<template>
    <div v-if="!isLoading">
        <DynamicForm v-if="formSteps.length && Object.keys(formData).length" :steps="formSteps" :model="formData" />
    </div>
    <div v-else class="centered-container">
        <div class="containermb-5">
            <div class="row">
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <div class="centered-container">
                            <div class="loading"></div>
                            <p class="text-font" style="margin-top: 10px; text-align: center">
                                Carregando...
                            </p>
                        </div>
                    </div>
                    <div class="col-2"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import DynamicForm from '../components/DynamicForm.vue';

export default {
    data() {
        return {
            formSteps: [],
            formData: {},
            isLoading: false
        };
    },
    async created() {
        this.isLoading = true;
        if (!this.isAuthenticated()) {
            this.$router.push("/login");
        }
        if (!this.isSpecialty()) {
            this.$router.push("/");
        }
        else {
            try {
                var result = await this.GetFormAssistence();
                if (result != null && result != undefined) {
                    this.formSteps = JSON.parse(result.steps);
                    this.formData = JSON.parse(result.data);
                }
            } catch (erro) {
                this.$toast.error('Erro ao carregar formulário.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
        }
        if (this.formData && this.formSteps.length) {
            this.isLoading = false;
        }
        else {
            this.$toast.error('Erro ao carregar formulário.', {
                timeout: 3000,
                closeOnClick: true
            });
            this.isLoading = false;
        }
    },
    components: {
        DynamicForm
    },
    methods: {
        ...mapGetters(["isAuthenticated"]),
        ...mapGetters(["isSpecialty"]),
        ...mapActions(["GetFormAssistence"]),
    }
};
</script>