import Home from './views/Home.vue'
import Login from './views/Login.vue'
import FormPacient from './views/FormPacient.vue'
import FormAssistance from './views/FormAssistance.vue'
import DailyRoutine from './views/DailyRoutine.vue'
import Register from './views/Register.vue'
import History from "@/views/History.vue";
import HistoryMenu from "@/views/HistoryMenu.vue";
import HistoryMe from './views/HistoryMe.vue'
import HistoryCompany from "@/views/HistoryCompany.vue";
import HistoryCompanyDetails from "@/views/HistoryCompanyDetails.vue";

import { createRouter, createWebHistory} from "vue-router";



const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        name: 'Login',
        component:Login,
        path:'/login',
        meta: {
            hideNavbar: true,
        }
    },
    {
        path: "/form",
        name: "FormPacient",
        component: FormPacient,
    },
    {
        path: "/assistance",
        name: "FormAssistance",
        component: FormAssistance,
    },
    {
        path: "/daily",
        name: "DailyRoutine",
        component: DailyRoutine,
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
    },
    {
        name: 'History',
        component:History,
        path:'/history',
    },
    {
        name: 'Menu',
        component:HistoryMenu,
        path:'/historymenu',
    },
    {
        name: 'HistoryMe',
        component:HistoryMe,
        path:'/historyme',
    },
    {
        name: 'HistoryCompanyDetails',
        component:HistoryCompanyDetails,
        path:'/historycompanydetails',
    },
    {
        name: 'HistoryCompany',
        component:HistoryCompany,
        path:'/historycompany',
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
