<template>
    <div class="container">
        <div v-if="isLoading === true">
            <div class="containermb-5">
                <div class="row">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="centered-container">
                                <div class="loading"></div>
                                <p class="text-font" style="margin-top: 10px; text-align: center">
                                    Carregando...
                                </p>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <!-- Termo -->
            <div v-if="currentValue == 0">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="backDaily()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-8">
                        <h2 class="style-font-bold">Termo</h2>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Eu {{ User.name }}, declaro ter realizado o atendimento médico do paciente, registrando
                        todas as
                        informações necessárias sobre o atendimento, decidindo pelo seu encaminhamento para
                        teleinterconsulta médica especializada.
                    </p>
                    <p class="justified-text">
                        Declaro que procedi com as devidas orientações do
                        paciente e/ou de seu acompanhante sobre o atendimento, esclarecendo, ainda, todas as dúvidas
                        sobre a realização e encaminhamento para a teleinterconsulta médica especializada.
                    </p>
                    <p class="justified-text">
                        Declaro,
                        ainda, minha responsabilidade exclusiva sobre o tratamento prescrito após a
                        teleinterconsulta
                        realizada por médico especialista da Doc4Doc.
                    </p>
                </div>
                <div class="row mt-2">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <div class="form-check d-flex justify-content-center align-items-center">
                            <input type="checkbox" style="margin-right: 10px;" class="form-check-input"
                                id="termsCheckbox" v-model="termsAccepted">
                            <label class="form-check-label justified-text" for="termsCheckbox">Li, concordo e aceito
                                todos os termos e condições de uso.</label>
                        </div>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="row flow-down ">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectStepInTerm">PRÓXIMO</button>
                    </div>
                </div>
            </div>

            <!-- Termo do passaporte -->
            <div v-if="currentValue == 230">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="backDaily()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-8">
                        <h2 class="style-font-bold">Termo</h2>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Você declara para os devidos fins de direito, sob as penas da lei, que teve acesso ao documento
                        de identificação do paciente, verificando sua veracidade. Declara ainda que os dados
                        apresentados são verdadeiros, restando ciente acerca da responsabilidade sob todos os efeitos e
                        danos causados pelas suas declarações?
                    </p>
                </div>
                <div class="row mt-2">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <div class="form-check d-flex justify-content-center align-items-center">
                            <input type="checkbox" style="margin-right: 10px;" class="form-check-input"
                                id="termsCheckbox" v-model="termsAcceptedPassaport">
                            <label class="form-check-label justified-text" for="termsCheckbox">Li, concordo e aceito
                                todos os termos e condições de uso.</label>
                        </div>
                    </div>
                    <div class="col-2"></div>
                </div>
                <div class="row flow-down ">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectStepInTermPassport">PRÓXIMO</button>
                    </div>
                </div>
            </div>

            <!-- Termo do paciente sem documentacao -->
            <div v-if="currentValue == 231">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Paciente sem CPF</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4 d-flex justify-content-center">
                        <img src="../assets/computerCpf.png" class="img-fluid" width="300" height="300">
                    </div>
                </div>
                <div class="content-container">
                    <div class="row mt-5">
                        <p><b>Termo de Responsabilidade para Cadastro de Paciente sem CPF</b></p>
                        <p class="justified-text">
                            Conforme estabelecido pela Resolução CFM nº 2.314/2022, que regulamenta a telemedicina no
                            Brasil, incluindo a teleinterconsulta, é fundamental assegurar a identificação inequívoca do
                            paciente. Esta identificação, muitas vezes realizada por meio do CPF, tem como objetivo
                            garantir
                            a correta associação das informações médicas ao paciente, evitando erros de identificação e
                            assegurando a integridade do seu histórico médico.
                        </p>
                        <p class="justified-text">
                            Entretanto, reconhecemos que pacientes em situações específicas, como indígenas e
                            estrangeiros
                            sem documentação brasileira podem não possuir CPF ou documentos de identificação adequados.
                            Por
                            isso, estamos permitindo o cadastro utilizando apenas o nome, a data de nascimento e o nome
                            da
                            mãe. No entanto, é importante ressaltar que esta abordagem pode apresentar riscos
                            adicionais,
                            incluindo a potencial infração à Resolução CFM e possíveis dificuldades na identificação
                            correta
                            do paciente e na integração das informações de saúde.
                        </p>
                        <p class="justified-text">
                            Ao prosseguir com o cadastro de interconsulta sem a utilização do CPF, o solicitante declara
                            estar ciente dos riscos envolvidos e assume a total responsabilidade pela precisão das
                            informações fornecidas e pelas penalidades que podem ser adotadas pelas autoridades
                            reguladoras
                            e instituições de saúde.
                        </p>
                    </div>
                    <div class="row mt-2">
                        <div>
                            <div class="form-check d-flex justify-content-center align-items-center">
                                <input type="checkbox" style="margin-right: 10px;" class="form-check-input-new"
                                    id="termsAcceptedWithoutCPF" v-model="termsAcceptedWithoutCPF">
                                <label class="form-check-label justified-text" for="termsAcceptedWithoutCPF">Declaro
                                    estar
                                    Declaro estar plenamente ciente dos riscos envolvidos ao solicitar uma
                                    teleinterconsulta
                                    para o paciente,
                                    os quais incluem, mas não se limitam, às possíveis penalidades decorrentes da
                                    infração à
                                    Resolução CFM 2.314/2022,
                                    bem como imprecisões no histórico médico do paciente.</label>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 80px;"></div>
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="redirectStepInTermPacientNotCpfTwo">PRÓXIMO</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Termo do paciente desacordado -->
            <div v-if="currentValue == 232">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="backDaily()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-8">
                        <h2 class="style-font-bold">Paciente adulto desacordado</h2>
                    </div>
                    <div class="col-8"></div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        A solicitação de teleinterconsulta destina-se a situações em que o paciente, no momento da
                        consulta, está impossibilitado de se identificar. Isso pode incluir casos em que o paciente está
                        desacordado, indigente ou vítima de transtorno mental que compromete sua capacidade de fornecer
                        informações pessoais ou de se comunicar.
                    </p>
                    <p class="justified-text">
                        Nessas circunstâncias, o solicitante declara e garante que não possui condições de coletar as
                        informações básicas do paciente, como nome, idade e CPF. Consequentemente, é ciente de que a
                        plataforma apenas disponibilizará a interconsulta para suporte, sem a emissão de relatório
                        médico já que o paciente e nem o solicitante não terão meios de comprovar a identidade de
                        maneira convencional.
                    </p>

                </div>
                <div class="row mt-2">
                    <div class="col-2"></div>
                </div>
                <div class="row flow-down ">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectStepInTermPacientUnconscious">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Termo do paciente sem documentacao desacordado -->
            <div v-if="currentValue == 233">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Paciente sem CPF</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4 d-flex justify-content-center">
                        <img src="../assets/computerCpf.png" class="img-fluid" width="300" height="300">
                    </div>
                </div>
                <div class="content-container">
                    <div class="row mt-5">
                        <p><b>Termo de Responsabilidade para Cadastro de Paciente sem CPF</b></p>
                        <p class="justified-text">
                            Conforme estabelecido pela Resolução CFM nº 2.314/2022, que regulamenta a telemedicina no
                            Brasil, incluindo a teleinterconsulta, é fundamental assegurar a identificação inequívoca do
                            paciente. Esta identificação, muitas vezes realizada por meio do CPF, tem como objetivo
                            garantir
                            a correta associação das informações médicas ao paciente, evitando erros de identificação e
                            assegurando a integridade do seu histórico médico.
                        </p>
                        <p class="justified-text">
                            Entretanto, reconhecemos que pacientes em situações específicas, como indígenas e
                            estrangeiros
                            sem documentação brasileira podem não possuir CPF ou documentos de identificação adequados.
                            Por
                            isso, estamos permitindo o cadastro utilizando apenas o nome, a data de nascimento e o nome
                            da
                            mãe. No entanto, é importante ressaltar que esta abordagem pode apresentar riscos
                            adicionais,
                            incluindo a potencial infração à Resolução CFM e possíveis dificuldades na identificação
                            correta
                            do paciente e na integração das informações de saúde.
                        </p>
                        <p class="justified-text">
                            Ao prosseguir com o cadastro de interconsulta sem a utilização do CPF, o solicitante declara
                            estar ciente dos riscos envolvidos e assume a total responsabilidade pela precisão das
                            informações fornecidas e pelas penalidades que podem ser adotadas pelas autoridades
                            reguladoras
                            e instituições de saúde.
                        </p>
                    </div>
                    <div class="row mt-2">
                        <div>
                            <div class="form-check d-flex justify-content-center align-items-center">
                                <input type="checkbox" style="margin-right: 10px;" class="form-check-input-new"
                                       id="termsAcceptedWithoutCPF" v-model="termsAcceptedWithoutCPF">
                                <label class="form-check-label justified-text" for="termsAcceptedWithoutCPF">Declaro
                                    estar
                                    Declaro estar plenamente ciente dos riscos envolvidos ao solicitar uma
                                    teleinterconsulta
                                    para o paciente,
                                    os quais incluem, mas não se limitam, às possíveis penalidades decorrentes da
                                    infração à
                                    Resolução CFM 2.314/2022,
                                    bem como imprecisões no histórico médico do paciente.</label>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 80px;"></div>
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="redirectStepInTermPacientNotCpfTree">PRÓXIMO</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Tipo Paciente adultos -->
            <div v-if="currentValue == 199">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro de paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Selecione uma das opções abaixo para cadastrar o paciente adulto:
                    </p>
                </div>
                <div class="row mt-3" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(202)" style="display: flex; align-items: center;">
                        <img src="../assets/pacient_cpf.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Paciente Adulto com CPF </h4>
                            <p class="style-font" style="margin: 0;">Tenha em mãos o CPF do paciente</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(201)" style="display: flex; align-items: center;">
                        <img src="../assets/search_rg_purple.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Paciente Adulto com RG</h4>
                            <p class="style-font" style="margin: 0;">Tenha em mãos o RG do paciente</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(230)" style="display: flex; align-items: center;">
                        <img src="../assets/search_passport.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Paciente Adulto com Passaporte</h4>
                            <p class="style-font" style="margin: 0;">Tenha em mãos o Passaporte do paciente</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(212)" style="display: flex; align-items: center;">
                        <img src="../assets/paciente_not_data.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Paciente Adulto sem documentação</h4>
                            <p class="style-font" style="margin: 0;">Exclusivo para indígenas, estrangeiros...</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(232)" style="display: flex; align-items: center;">
                        <img src="../assets/unconscious_patient.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Paciente Adulto desacordado</h4>
                            <p class="style-font" style="margin: 0;">Exclusivo para pacientes desacordados e sem
                                documentação</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Tipo Paciente -->
            <div v-if="currentValue == 200">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro de paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Selecione uma das opções abaixo para cadastrar o paciente:
                    </p>
                </div>
                <div class="row mt-3" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(2)" style="display: flex; align-items: center;">
                        <img src="../assets/pacient_cpf.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Criança com CPF </h4>
                            <p class="style-font" style="margin: 0;">Tenha em mãos o CPF do paciente</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(5)" style="display: flex; align-items: center;">
                        <img src="../assets/pacient_not_cpf.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Criança sem CPF (CPF da mãe)</h4>
                            <p class="style-font" style="margin: 0;">Tenha em mãos o CPF da mãe</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(9)" style="display: flex; align-items: center;">
                        <img src="../assets/paciente_not_data.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Criança sem documentação</h4>
                            <p class="style-font" style="margin: 0;">Exclusivo para indígenas, estrangeiros...</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Paciente possue RG  -->
            <div v-if="currentValue == 201">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Digite abaixo o RG do paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Digite o RG abaixo. Se não estiver na base, será necessário realizar um breve cadastro.
                    </p>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="rg">Digite o RG do paciente:</label>
                        <input class="input-field" type="text" v-model="rg" id="rg" name="rg" placeholder="0000000000"
                            v-mask="'##########'">
                    </div>
                </div>

                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="verifyPatientRG()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Paciente adulto possue CPF  -->
            <div v-if="currentValue == 202">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Digite abaixo o CPF do paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Digite o CPF abaixo. Se não estiver na base, será necessário realizar um breve cadastro.
                    </p>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="cpf">Digite o CPF do paciente:</label>
                        <input class="input-field" type="text" v-model="cpf" id="cpf" name="cpf" maxlength="14"
                            placeholder="000.000.000-00" v-mask="'###.###.###-##'">
                    </div>

                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="verifyPatientAdultoCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Preencher Cadastro RG Paciente -->
            <div v-if="currentValue == 203">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro de paciente Adulto</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Por favor, faça um breve cadastro deste paciente
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="rg">Digite abaixo seu RG do paciente:</label>
                        <input class="input-field" type="text" v-model="rg" id="rg" name="rg" maxlength="12"
                            placeholder="000000000000" v-mask="'############'">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="rg">Digite o órgão expedidor:</label>
                        <input class="input-field" type="text" v-model="document_issuer" id="document_issuer" name="document_issuer" maxlength="6"
                               placeholder="Ex: SSP" v-mask="'AAAAAA'">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="name">Digite abaixo o nome do paciente:</label>
                        <input class="input-field" type="text" v-model="name" id="name" name="name" maxlength="255">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDate">Digite abaixo data de nascimento da
                            paciente:</label>
                        <input class="input-field" type="text" v-model="birthDate" id="birthDate" name="date"
                            placeholder="00/00/0000" v-mask="'##/##/####'" />
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="registryPatientAdultRG()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Preencher Cadastro CPF Paciente adulto -->
            <div v-if="currentValue == 204">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro de paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Por favor, faça um breve cadastro deste paciente.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="cpf">Digite abaixo seu CPF do paciente:</label>
                        <input class="input-field" type="text" v-model="cpf" id="cpf" name="cpf" maxlength="14"
                            placeholder="000.000.000-00" v-mask="'###.###.###-##'">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDate">Digite abaixo data de nascimento da
                            paciente:</label>
                        <input class="input-field" type="text" v-model="birthDate" id="birthDate" name="date"
                            placeholder="00/00/0000" v-mask="'##/##/####'" />
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="registryPatientAdultCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Busca por passaporte Paciente adulto -->
            <div v-if="currentValue == 205">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Busca por passaporte</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Esta jornada é exclusiva para pacientes sem CPF brasileiro, que possuam passaporte, como
                            estrangeiros, turistas, residentes temporários ou em situações similares, nas quais não haja
                            acesso ao CPF brasileiro.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="passaporte">Digite o nº do Passaporte do
                            paciente:</label>
                        <input class="input-field" type="text" v-model="passaporte" id="passaporte" name="passaporte"
                            maxlength="8" placeholder="000.000">
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="verifyPatientAdultPassaporte()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Preencher Cadastro Paciente sem documento -->
            <div v-if="currentValue == 206">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro
                        </h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            ⚠️ ATENÇÃO: Esta é uma JORNADA EXCLUSIVA PARA PACIENTES SEM DOCUMENTOS!
                        </p>
                        <p class="justified-text">
                            Tem certeza de que deseja seguir com essa solicitação? A responsabilidade sobre a escolha de
                            não identificar o paciente será do médico responsável pelo preenchimento.
                        </p>
                        <p class="justified-text">
                            Se a(o) paciente tem documentos, favor retornar e escolher a jornada correta. Ressaltamos
                            que o todo cadastramento de pacientes realizado é auditado para fins de confirmação de
                            veracidade das informações.
                        </p>
                        <p class="justified-text">
                            O preenchimento inadequado poderá ensejar responsabilizações futuras para o médico
                            responsável pelo cadastro.
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="input-container">
                        <label class="label-text justified-text" for="nameChildren">Digite o nome do paciente:</label>
                        <input class="input-field" type="text" v-model="nameAdult" id="nameChildren" name="nameChildren"
                            maxlength="50">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDateAdult">Digite abaixo data de
                            nascimento do Paciente:</label>
                        <input class="input-field" type="text" v-model="birthDateAdult" id="birthDateAdult"
                            name="birthDateAdult" placeholder="00/00/0000" v-mask="'##/##/####'"
                            @blur="validateBothDates(true)" />
                        <div v-if="showError.birthDateAdult" class="alert alert-danger mt-3" role="alert">
                            {{ errorMessage }}
                        </div>
                    </div>
                </div>
                <div style="margin-top: 120px;"></div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="handleNextClickPatientNotCPF()"
                            id="handleNextClickPatientNotCPF">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Preencher Cadastro Paciente sem documento -->
            <div v-if="currentValue == 207">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro
                        </h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            ⚠️ ATENÇÃO: Esta é uma JORNADA EXCLUSIVA PARA PACIENTES DESACORDADOS!
                        </p>
                        <p class="justified-text">
                            Tem certeza de que deseja seguir com essa solicitação? A responsabilidade sobre a escolha de
                            não identificar o paciente será do médico responsável pelo preenchimento.
                        </p>
                        <p class="justified-text">
                            Se a(o) paciente tem documentos, favor retornar e escolher a jornada correta. Ressaltamos
                            que o todo cadastramento de pacientes realizado é auditado para fins de confirmação de
                            veracidade das informações.
                        </p>
                        <p class="justified-text">
                            O preenchimento inadequado poderá ensejar responsabilizações futuras para o médico
                            responsável pelo cadastro.
                        </p>
                    </div>
                </div>
                <div style="margin-top: 120px;"></div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="handleNextClickPatientUnconscious()"
                            id="handleNextClickPatientNotCPF">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Preencher Cadastro do passaporte Paciente adulto -->
            <div v-if="currentValue == 208">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Esta jornada é exclusiva para pacientes sem CPF brasileiro, que possuam passaporte,
                            como estrangeiros, turistas, residentes temporários ou em situações similares,
                            nas quais não haja acesso ao CPF brasileiro.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="passaporte">Digite o nº do Passaporte do
                            paciente:</label>
                        <input class="input-field" type="text" v-model="passaporte" id="passaporte" name="passaporte"
                               maxlength="8" placeholder="000.000">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="name">Digite o nome do paciente:</label>
                        <input class="input-field" type="text" v-model="name" id="name" name="name">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDate">Digite abaixo data de nascimento da paciente:</label>
                        <input class="input-field" type="text" v-model="birthDate" id="birthDate" name="date"
                               placeholder="00/00/0000" v-mask="'##/##/####'" />
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="registryPatientAdultPassaporte()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Preencher Cadastro Paciente adulto indigente -->
            <div v-if="currentValue == 212">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Paciente adulto sem documentos
                        </h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            A solicitação de teleinterconsulta destina-se exclusivamente a situações em que o paciente
                            comparece à consulta sem os documentos de identificação devido a circunstâncias de
                            vulnerabilidade. Estas incluem, mas não se limitam a, pessoas em situação de rua e mulheres
                            vítimas de violência doméstica, que por diversos motivos podem não estar portando documentos
                            no momento da consulta. Nesses casos específicos, o paciente poderá se identificar através
                            do fornecimento do nome completo e número de CPF, porém, não terá meios de comprovar sua
                            identidade de maneira convencional.
                        </p>
                    </div>
                </div>
                <div style="margin-top: 120px;"></div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="redirectStepInTermPacientNotCpf">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Escolha para a classificacao do caso -->
            <div v-if="currentValue == 1">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Classificação do caso</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Por favor, escolha a classificação do caso abaixo para que possamos continuar com a sua
                        solicitação.
                    </p>
                </div>
                <div class="row mt-3" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(199)" style="display: flex; align-items: center;">
                        <img src="../assets/ic_adult.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Adulto </h4>
                            <p class="style-font" style="margin: 0;">Pacientes Adultos</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
                <div class="row mt-5" style="cursor: pointer">
                    <div class="border-in" @click="redirectStep(200)" style="display: flex; align-items: center;">
                        <img src="../assets/ic_child.png" style="margin-right: 10px;">
                        <div style="display: flex; flex-direction: column;">
                            <h4 class="style-font" style="margin: 0;">Pediatria</h4>
                            <p class="style-font" style="margin: 0;">Pacientes pediátricos</p>
                        </div>
                        <div style="display: flex; align-items: center; margin-left: auto;">
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Paciente possue CPF  -->
            <div v-if="currentValue == 2">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Digite abaixo o CPF do paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Digite o CPF abaixo. Se não estiver na base, será necessário realizar um breve cadastro.
                    </p>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="cpf">Digite o CPF do paciente:</label>
                        <input class="input-field" type="text" v-model="cpf" id="cpf" name="cpf" maxlength="14"
                            placeholder="000.000.000-00" v-mask="'###.###.###-##'">
                    </div>

                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="verifyPatientCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Preencher Cadastro CPF Paciente -->
            <div v-if="currentValue == 3">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro de paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Por favor, faça um breve cadastro deste paciente
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="cpf">Digite abaixo seu CPF do paciente:</label>
                        <input class="input-field" type="text" v-model="cpf" id="cpf" name="cpf" maxlength="14"
                            placeholder="000.000.000-00" v-mask="'###.###.###-##'">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDate">Digite abaixo data de nascimento da
                            paciente:</label>
                        <input class="input-field" type="text" v-model="birthDate" id="birthDate" name="date"
                            placeholder="00/00/0000" v-mask="'##/##/####'" />
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="registryPatientCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: CPF mãe Paciente  -->
            <div v-if="currentValue == 5">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Digite abaixo o CPF da Mãe</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <p class="justified-text">
                        Digite o CPF abaixo. Se não estiver na base, será necessário realizar um breve cadastro.
                    </p>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="cpfMother">Digite o CPF da mãe:</label>
                        <input class="input-field" type="text" v-model="cpfMother" id="cpfMother" name="cpfMother"
                            maxlength="14" placeholder="000.000.000-00" v-mask="'###.###.###-##'">
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="verifyPatientMotherCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Preencher Cadastro Mãe CPF Paciente -->
            <div v-if="currentValue == 6">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro de paciente</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Por favor, realize um breve cadastro e tenha em mãos os dados da mãe como data de nascimento
                            e número de CPF
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="cpfMother">Digite o CPF da mãe:</label>
                        <input class="input-field" type="text" v-model="cpfMother" id="cpfMother" name="cpfMother"
                            maxlength="14" placeholder="000.000.000-00" v-mask="'###.###.###-##'">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDateMother">Digite a data de nascimento da
                            mãe:</label>
                        <input class="input-field" type="text" v-model="birthDateMother" id="birthDateMother"
                            name="date" placeholder="00/00/0000" v-mask="'##/##/####'" />
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="registryPatientMotherCPF()">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Informação Criança -->
            <div v-if="currentValue == 7">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Paciente cadastrado</h2>
                    </div>
                </div>
                <div class="row">
                    <div style="background-color: #F2F2F2;">
                        <p style="margin-top: 15px;" class="justified-text">
                            Paciente: {{ PatientMother.name }} - Idade: {{ calculateAge(PatientMother.birthdate) }}
                        </p>
                    </div>
                </div>
                <div v-if="Childrens.length > 0">
                    <div class="row mt-2">
                        <div>
                            <p class="justified-text">
                                Selecione uma criança para admitir:
                            </p>
                        </div>
                    </div>
                </div>
                <div v-for="(children) in Childrens" :key="children.id" class="row mt-2">
                    <div class="row mt-3" style="cursor: pointer">
                        <div class="border-in" @click="redirectAssistanceChildren(children)"
                            style="display: flex; align-items: center;">
                            <img src="../assets/ic_child.png" style="margin-right: 10px;">
                            <div style="display: flex; flex-direction: column;">
                                <h4 class="text-font-bold" style="margin: 0;">{{ children.name }}</h4>
                                <p class="text-font-bold" style="margin: 0;">{{ children.birthdate }}</p>
                            </div>
                            <div style="display: flex; align-items: center; margin-left: auto;">
                                <font-awesome-icon :icon="['fas', 'chevron-right']" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <button class="btn-noborder" style="cursor: pointer" @click="redirectStep(8)">+ ADICIONAR OUTRA
                        CRIANÇA</button>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Preencher Cadastro criança CPF mãe -->
            <div v-if="currentValue == 8">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Adicionar criança</h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Esta opção é exclusiva para adicionar uma criança vinculada à mesma mãe.
                        </p>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="input-container">
                        <label class="label-text justified-text" for="nameChildren">Digite o nome da criança:</label>
                        <input class="input-field" type="text" v-model="nameChildren" id="nameChildren"
                            name="nameChildren">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDateChildren">Digite a data de nascimento da
                            criança:</label>
                        <input class="input-field" type="text" v-model="birthDateChildren" id="birthDateChildren"
                            name="date" placeholder="00/00/0000" v-mask="'##/##/####'"
                            @blur="validateBothDates(true)" />
                        <span v-if="birthDateError" class="error-message">{{ birthDateError }}</span>
                    </div>
                    <div v-if="showError.birthDateChildren" class="alert alert-danger" role="alert">
                        {{ errorMessage }}
                    </div>
                </div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="registryChildrenMother()"
                            :disabled="isButtonDisabled">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Paciente e Mãe sem CPF -->
            <div v-if="currentValue == 9">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Paciente sem CPF</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4 d-flex justify-content-center">
                        <img src="../assets/computerCpf.png" class="img-fluid" width="300" height="300">
                    </div>
                </div>
                <div class="content-container">
                    <div class="row mt-5">
                        <p><b>Termo de Responsabilidade para Cadastro de Paciente sem CPF</b></p>
                        <p class="justified-text">
                            Conforme estabelecido pela Resolução CFM nº 2.314/2022, que regulamenta a telemedicina no
                            Brasil, incluindo a teleinterconsulta, é fundamental assegurar a identificação inequívoca do
                            paciente. Esta identificação, muitas vezes realizada por meio do CPF, tem como objetivo
                            garantir a correta associação das informações médicas ao paciente, evitando erros de
                            identificação e assegurando a integridade do seu histórico médico.
                        </p>
                        <p class="justified-text">
                            Entretanto, reconhecemos que pacientes em situações específicas, como indígenas e
                            estrangeiros sem documentação brasileira podem não possuir CPF ou documentos de
                            identificação adequados. Por isso, estamos permitindo o cadastro utilizando apenas o nome, a
                            data de nascimento e o nome da mãe. No entanto, é importante ressaltar que esta abordagem
                            pode apresentar riscos adicionais, incluindo a potencial infração à Resolução CFM e
                            possíveis dificuldades na identificação correta do paciente e na integração das informações
                            de saúde.
                        </p>
                        <p class="justified-text">
                            Ao prosseguir com o cadastro de interconsulta sem a utilização do CPF, o solicitante declara
                            estar ciente dos riscos envolvidos e assume a total responsabilidade pela precisão das
                            informações fornecidas e pelas penalidades que podem ser adotadas pelas autoridades
                            reguladoras e instituições de saúde.
                        </p>
                    </div>
                    <div class="row mt-2">
                        <div>
                            <div class="form-check d-flex justify-content-center align-items-center">
                                <input type="checkbox" style="margin-right: 10px;" class="form-check-input-new"
                                    id="termsCheckbox" v-model="termsCheckbox">
                                <label class="form-check-label justified-text" for="termsCheckbox">Declaro estar
                                    plenamente ciente dos riscos envolvidos ao solicitar uma teleinterconsulta para o
                                    paciente, os quais incluem, mas não se limitam, às possíveis penalidades decorrentes
                                    da infração à Resolução CFM 2.314/2022, bem como imprecisões no histórico médico do
                                    paciente.</label>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 80px;"></div>
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="redirectFormStep(10)">PRÓXIMO</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Dados Paciente: Preencher Cadastro Paciente indigente -->
            <div v-if="currentValue == 10">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cadastro
                        </h2>
                    </div>
                </div>
                <div class="row mt-5">
                    <div>
                        <p class="justified-text">
                            Esta jornada é exclusiva para crianças sem CPF, que estejam acompanhadas por pais indígenas,
                            pessoas em situação de rua, estrangeiros ou em situações similares, nas quais não haja
                            acesso ao CPF brasileiro.
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="input-container">
                        <label class="label-text justified-text" for="nameChildren">Digite o nome da criança:</label>
                        <input class="input-field" type="text" v-model="nameChildren" id="nameChildren"
                            name="nameChildren" maxlength="50">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDateChildren">Digite abaixo data de
                            nascimento da
                            criança:</label>
                        <input class="input-field" type="text" v-model="birthDateChildren" id="birthDateChildren"
                            name="birthDateChildren" placeholder="00/00/0000" v-mask="'##/##/####'"
                            @blur="validateBothDates(true)" />
                        <div v-if="showError.birthDateChildren" class="alert alert-danger mt-3" role="alert">
                            {{ errorMessage }}
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="nameMother">Nome da mãe:</label>
                        <input class="input-field" type="text" v-model="nameMother" id="nameMother" name="nameMother"
                            maxlength="50">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="input-container">
                        <label class="label-text justified-text" for="birthDate">Digite abaixo data de nascimento da
                            mãe:</label>
                        <input class="input-field" type="text" v-model="birthDate" id="birthDate" name="birthDate"
                            placeholder="00/00/0000" v-mask="'##/##/####'" @blur="validateBothDates(false)" />
                        <div v-if="showError.birthDate" class="alert alert-danger mt-3" role="alert">
                            {{ errorMessage }}
                        </div>
                    </div>
                </div>
                <div style="margin-top: 120px;"></div>
                <div class="row flow-down">
                    <div class="attentive-buttons">
                        <button class="btn-default" @click="handleNextClickPatientAndMotherNotCPF()"
                            id="handleNextClickPatientAndMotherNotCPF" :disabled="isButtonDisabled">PRÓXIMO</button>
                    </div>
                    <div class="attentive-buttons" style="margin-top: 20px">
                        <button class="btn-cancel" @click="redirectStep(11)">CANCELAR</button>
                    </div>
                </div>
            </div>

            <!-- Cancelar Jornada -->
            <div v-if="currentValue == 11">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Cancelar</h2>
                    </div>
                </div>
                <div class="row mt-3 justify-content-center">
                    <div class="col-12 col-md-4 d-flex justify-content-center">
                        <img src="../assets/cancel.png" class="img-fluid" width="300" height="300">
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <p style="color: #6200EE;"><b>Confirmação</b></p>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <p style="color: #777777;">Tem certeza de deseja cancelar a jornada?</p>
                    </div>
                </div>
                <div class="content-container">
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="previousItem()">CONTINUAR AQUI</button>
                        </div>
                        <div class="attentive-buttons" style="margin-top: 20px">
                            <button class="btn-cancel" @click="backDaily()">SAIR</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Confirmação CPF mãe -->
            <div v-if="currentValue == 12">
                <div class="row">
                    <div class="col-2">
                        <button class="button-back mx-3" @click="previousItem()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10">
                        <h2 class="style-font-bold">Confirmação</h2>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-4 d-flex justify-content-center">
                        <img src="../assets/mother_confirm.png" class="img-fluid" width="300" height="300">
                    </div>
                </div>
                <div class="content-container">
                    <div class="row mt-5">
                        <p class="justified-text">
                            <b>Por favor, confirme se os dados da mãe que deseja vincular à criança estão corretos
                                abaixo:</b>
                        </p>
                    </div>
                    <div class="row mt-3">
                        <p class="justified-text">
                            Mãe: {{ PatientMother.name }} - {{ calculateAge(PatientMother.birthdate) }}
                        </p>
                        <p class="justified-text">
                            CPF: {{ formatCPF(PatientMother.document) }}
                        </p>
                    </div>
                    <div style="margin-top: 80px;"></div>
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="redirectStep(7)">SIM</button>
                        </div>
                        <div class="attentive-buttons" style="margin-top: 20px">
                            <button class="btn-cancel" @click="redirectStep(5)">NÃO</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 15px;"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

class Node {
    constructor(data) {
        this.data = data;
        this.previous = null;
        this.next = null;
    }
}

class LinkedList {
    constructor() {
        this.head = null;
        this.tail = null;
    }

    add(data) {
        const newNode = new Node(data);
        if (!this.head) {
            this.head = newNode;
            this.tail = newNode;
        } else {
            this.tail.next = newNode;
            newNode.previous = this.tail;
            this.tail = newNode;
        }
    }

    removeLast() {
        if (!this.tail) {
            return;
        }
        if (this.head === this.tail) {
            this.head = null;
            this.tail = null;
            return;
        }
        this.tail = this.tail.previous;
        this.tail.next = null;
    }

    toArrayWithNeighbors() {
        let current = this.head;
        const array = [];
        while (current) {
            const item = {
                value: current.data,
                previous: current.previous ? current.previous.data : null,
                next: current.next ? current.next.data : null
            };
            array.push(item);
            current = current.next;
        }
        return array;
    }
}

export default {
    name: `FormRegister`,
    computed: {
        ...mapGetters({ Patient: "StatePatient", PatientMother: "StatePatientMother", User: "StateUser", Childrens: "StateChildrens", Specialty: "StateSpecialty" }),
    },
    data() {
        return {
            //Journey
            linkedList: new LinkedList(),
            currentItem: 0,
            currentObject: null,
            currentValue: 0,
            isLoading: false,
            //Others
            cpf: "",
            rg: "",
            cpfMother: "",
            birthDate: "",
            birthDateMother: "",
            nameChildren: null,
            nameMother: null,
            birthDateChildren: "",
            nameAdult: null,
            birthDateAdult: "",
            termsAccepted: false,
            termsAcceptedPassaport: false,
            termsAcceptedWithoutCPF: false,
            termsCheckbox: false,
            showError: {
                birthDate: false,
                birthDateChildren: false,
            },
            errorMessage: '',
            isFormValid: true,
            isButtonDisabled: false,
        };
    },
    async created() {
        if (!this.isAuthenticated() || this.User == null) {
            this.$router.push("/login");
        }
        else {
            this.linkedList.add(0);//Termo
        }
    },
    methods: {
        ...mapActions(["GetPatient"]),
        ...mapActions(["ValidationDocument"]),
        ...mapActions(["RegisterPatient"]),
        ...mapActions(["GetPatientById"]),
        ...mapActions(["GetChildrens"]),
        ...mapGetters(["isAuthenticated"]),
        validateBirthDate(dateToValidate, isChildren) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (!dateToValidate) {
                this.errorMessage = 'Data de nascimento inválida.';
                this.isFormValid = false;
                return;
            }

            const [day, month, year] = dateToValidate.split('/');

            if (!day || !month || !year || day.length !== 2 || month.length !== 2 || year.length !== 4) {
                this.errorMessage = 'Data de nascimento inválida.';
                this.isFormValid = false;
                return;
            }

            const inputDate = new Date(year, month - 1, day);
            inputDate.setHours(0, 0, 0, 0);

            if (isNaN(inputDate.getTime())) {
                this.errorMessage = 'Data de nascimento inválida.';
                if (!isChildren) {
                    this.showError.birthDate = true;
                } else {
                    this.showError.birthDateChildren = true;
                }
                this.isFormValid = false;
                this.isButtonDisabled = true;
                return;
            }

            if (inputDate.getTime() > today.getTime()) {
                this.errorMessage = 'A data de nascimento não pode ser maior que a data de hoje.';
                if (!isChildren) {
                    this.showError.birthDate = true;
                } else {
                    this.showError.birthDateChildren = true;
                }
                this.isFormValid = false;
                this.isButtonDisabled = true;
            } else {

                if (!isChildren) {
                    this.showError.birthDate = false;
                } else {
                    this.showError.birthDateChildren = false;
                }
                this.isFormValid = true;
                this.isButtonDisabled = false;
            }
        },
        validateBothDates(isChildren) {
            if (this.birthDateChildren != '' && isChildren) { this.validateBirthDate(this.birthDateChildren, isChildren) }
            if (this.birthDate != '' && !isChildren) { this.validateBirthDate(this.birthDate, isChildren) }
        },
        submitForm() {
            this.validateBirthDate();

            if (!this.isFormValid) {
                this.showError = true;
            }
        },
        handleNextClickPatientAndMotherNotCPF() {
            this.registerPatientAndMotherNotCPF();
            this.submitForm();
        },
        handleNextClickPatientNotCPF() {
            this.registerPatientAdultNotCPF();
            this.submitForm();
        },
        handleNextClickPatientUnconscious() {
            this.registerPatientUnconscious();
            this.redirectAssistance();
        },
        previousItem() {
            if (this.currentItem > 0) {
                this.currentItem--;
                this.currentObject = this.linkedList.toArrayWithNeighbors()[this.currentItem];
                this.currentValue = this.currentObject.value
                this.linkedList.removeLast();
            }
        },
        nextItem() {
            const linkedListLength = this.linkedList.toArrayWithNeighbors().length;
            if (this.currentItem < linkedListLength - 1) {
                this.currentItem++;
                this.currentObject = this.linkedList.toArrayWithNeighbors()[this.currentItem];
                this.currentValue = this.currentObject.value
            }
        },
        redirectStepInTerm() {
            if (this.termsAccepted == false || this.termsAccepted == null) {
                this.$toast.warning('É preciso aceitar os Termos e condições de Uso para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.redirectStep(1);
            }
        },
        redirectStepInTermPassport() {
            if (this.termsAcceptedPassaport == false || this.termsAcceptedPassaport == null) {
                this.$toast.warning('É preciso aceitar os Termos e condições de Uso para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.redirectStep(205);
            }
        },
        redirectStepInTermPacientNotCpf() {
            this.redirectStep(231);
        },
        redirectStepInTermPacientNotCpfTwo() {
            if (this.termsAcceptedWithoutCPF == false || this.termsAcceptedWithoutCPF == null) {
                this.$toast.warning('É preciso aceitar os Termos e condições de Uso para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.redirectStep(206);
            }
        },
        redirectStepInTermPacientNotCpfTree() {
            if (this.termsAcceptedWithoutCPF == false || this.termsAcceptedWithoutCPF == null) {
                this.$toast.warning('É preciso aceitar os Termos e condições de Uso para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.redirectStep(207);
            }
        },
        redirectStepInTermPacientUnconscious() {
            this.redirectStep(233);
        },
        redirectStepInTermPacientNotCpfUnconscious() {
            this.redirectStep(207);
        },
        formatCPF(cpf) {
            return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        },
        async verifyPatientCPF() {
            if (!this.validateCPF(this.cpf)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.isLoading = true;
                await this.GetPatient({'number':this.cpf,  'type':'CPF'})
                this.isLoading = false;
                if (this.Patient) {//Tem CPF Cadastrado
                    this.redirectAssistance();
                }
                else {//Não tem CPF Cadastro
                    this.redirectStep(3)
                }
            }
        },
        async verifyPatientAdultoCPF() {
            if (!this.validateCPF(this.cpf)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.isLoading = true;
                await this.GetPatient({'number':this.cpf, 'type':'CPF'})
                this.isLoading = false;
                if (this.Patient) {
                    this.redirectAssistance();
                }
                else {//Não tem CPF Cadastro
                    this.redirectStep(204)
                }
            }
        },
        async verifyPatientRG() {
            if (!this.validateRG(this.rg)) {
                this.$toast.warning('RG inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.isLoading = true;
                await this.GetPatient({'number':this.rg, 'type':'RG'})
                this.isLoading = false;
                if (this.Patient) {
                    this.redirectAssistance();
                }
                else {
                    this.redirectStep(203)
                }
            }
        },
        async registryPatientCPF() {
            if (!this.validateCPF(this.cpf)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (!this.validateDate(this.birthDate)) {
                    this.$toast.warning('Data de Nascimento Incorreta.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    let patientData = {
                        "cpf": this.cpf,
                        "birthDate": this.birthDate
                    }
                    this.isLoading = true;
                    let result = await this.ValidationDocument(patientData);
                    this.isLoading = false;
                    if (result) {
                        const dateAPI = new Date(result.birthdate);
                        const [day, month, year] = this.birthDate.split('/');
                        const dateForm = new Date(`${year}-${month}-${day}`);
                        const dateAPIStr = dateAPI.toISOString().split('T')[0];
                        const dateFormStr = dateForm.toISOString().split('T')[0];
                        if (dateAPIStr !== dateFormStr) {
                            this.$toast.warning('Data de Nascimento Incorreta.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                        else {
                            this.isLoading = true;
                            try {
                                const day = String(dateAPI.getDate()).padStart(2, '0');
                                const month = String(dateAPI.getMonth() + 1).padStart(2, '0');
                                const year = dateAPI.getFullYear();
                                const formattedDate = `${day}/${month}/${year}`;
                                const patientInfo = {
                                    "document": result.document.replace(/[.-]/g, ''),
                                    "document_type": "CPF",
                                    "birthdate": formattedDate,
                                    "name": result.name,
                                    "document_issuer": null,
                                    "phone": null
                                }

                                await this.RegisterPatient(patientInfo)

                                this.redirectAssistance();
                            }
                            catch (erro) {
                                this.$toast.error('Erro ao cadastrar paciente.', {
                                    timeout: 3000,
                                    closeOnClick: true
                                });
                            }
                            this.isLoading = false;
                        }
                    }
                    else {
                        this.$toast.warning('Dados incorretos.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                }
            }
        },
        async registryPatientAdultCPF() {
            if (!this.validateCPF(this.cpf)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            } else {
                let patientData = {
                    "cpf": this.cpf,
                    "birthDate": this.birthDate
                }
                this.isLoading = true;
                let result = await this.ValidationDocument(patientData);
                this.isLoading = false;
                if (result) {
                    const dateAPI = new Date(result.birthdate);
                    this.isLoading = true;
                    try {
                        const day = String(dateAPI.getDate()).padStart(2, '0');
                        const month = String(dateAPI.getMonth() + 1).padStart(2, '0');
                        const year = dateAPI.getFullYear();
                        const formattedDate = `${day}/${month}/${year}`;
                        const patientInfo = {
                            "document": result.document.replace(/[.-]/g, ''),
                            "document_type": "CPF",
                            "birthdate": formattedDate,
                            "name": result.name,
                            "document_issuer": null,
                            "phone": null
                        }

                        await this.RegisterPatient(patientInfo)

                        this.redirectAssistance();
                    }
                    catch (erro) {
                        this.$toast.error('Erro ao cadastrar paciente.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                    this.isLoading = false;
                } else {
                    this.$toast.warning('Dados incorretos.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
            }

        },

        async verifyPatientAdultPassaporte() {
            if (!this.validatePassaport(this.passaporte)) {
                this.$toast.warning('Passaporte inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.isLoading = true;
                await this.GetPatient({'number':this.passaporte,  'type':'PASSAPORTE'})
                this.isLoading = false;
                if (this.Patient) {
                    this.redirectAssistance();
                }
                else {
                    this.redirectStep(208)
                }
            }
        },
        async verifyPatientMotherCPF() {
            if (!this.validateCPF(this.cpfMother)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.isLoading = true;
                await this.GetPatient({'number':this.cpfMother,  'type':'CPF'})
                if (this.Patient) {//Tem CPF Cadastrado
                    this.$store.commit('setPatientMother', this.Patient);
                    if (this.PatientMother != null) {
                        await this.GetChildrens(this.PatientMother)
                    }
                    this.redirectStep(12)
                }
                else {//Não tem CPF Cadastro
                    this.redirectStep(6)
                }
                this.isLoading = false;
            }
        },
        async registryPatientMotherCPF() {
            if (!this.validateCPF(this.cpfMother)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (!this.validateDate(this.birthDateMother)) {
                    this.$toast.warning('Data de Nascimento Incorreta.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    let patientData = {
                        "cpf": this.cpfMother,
                        "birthDate": this.birthDateMother
                    }
                    this.isLoading = true;
                    let result = await this.ValidationDocument(patientData);
                    this.isLoading = false;
                    if (result) {
                        const dateAPI = new Date(result.birthdate);
                        const [day, month, year] = this.birthDateMother.split('/');
                        const dateForm = new Date(`${year}-${month}-${day}`);
                        const dateAPIStr = dateAPI.toISOString().split('T')[0];
                        const dateFormStr = dateForm.toISOString().split('T')[0];
                        if (dateAPIStr !== dateFormStr) {
                            this.$toast.warning('Data de Nascimento Incorreta.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                        else {
                            this.isLoading = true;
                            try {
                                const day = String(dateAPI.getDate()).padStart(2, '0');
                                const month = String(dateAPI.getMonth() + 1).padStart(2, '0');
                                const year = dateAPI.getFullYear();
                                const formattedDate = `${day}/${month}/${year}`;
                                const patientInfo = {
                                    "document": result.document.replace(/[.-]/g, ''),
                                    "document_type": "CPF",
                                    "birthdate": formattedDate,
                                    "name": result.name,
                                    "document_issuer": null,
                                    "phone": null
                                }
                                await this.RegisterPatient(patientInfo)
                                this.$store.commit('setPatientMother', this.Patient);
                                if (this.PatientMother != null) {
                                    await this.GetChildrens(this.PatientMother)
                                }
                                this.redirectStep(12)
                            }
                            catch (erro) {
                                this.$toast.error('Erro ao cadastrar paciente.', {
                                    timeout: 3000,
                                    closeOnClick: true
                                });
                            }
                            this.isLoading = false;
                        }
                    }
                    else {
                        this.$toast.warning('Dados incorretos.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                }
            }
        },
        async registryChildrenMother() {
            if (this.nameChildren == null || this.nameChildren == '') {
                this.$toast.warning('Preencha o nome.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (!this.validateDate(this.birthDateChildren)) {
                    this.$toast.warning('Data de Nascimento Incorreta.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    const patientInfo = {
                        "document": null,
                        "document_type": null,
                        "birthdate": this.birthDateChildren,
                        "name": this.nameChildren,
                        "document_issuer": null,
                        "phone": null,
                        "patient_id": this.PatientMother.id
                    }
                    this.isLoading = true;
                    await this.RegisterPatient(patientInfo)
                    if (this.PatientMother != null) {
                        await this.GetChildrens(this.PatientMother)
                    }
                    this.redirectStep(7);
                    this.isLoading = false;
                }
            }
        },
        redirectAssistanceChildren(children) {
            this.$store.commit("setPatient", children);
            this.$router.push("/assistance");
        },
        redirectFormStep(step) {
            if (this.termsCheckbox == false || this.termsCheckbox == null) {
                this.$toast.warning('É preciso aceitar os Termos e condições de Uso para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            this.linkedList.add(step);
            this.nextItem();
        },
        async registerPatientAndMotherNotCPF() {
            if (this.nameMother == null || this.nameMother == "") {
                this.$toast.warning('Preencha o nome da mãe.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (this.nameChildren == null || this.nameChildren == "") {
                    this.$toast.warning('Preencha o nome da criança.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    if (!this.validateDate(this.birthDateChildren)) {
                        this.$toast.warning('Data de Nascimento Criança Incorreta.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                    else {
                        if (!this.validateDate(this.birthDate)) {
                            this.$toast.warning('Data de Nascimento Mãe Incorreta.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                        else {
                            const patientInfoMother = {
                                "document": null,
                                "document_type": null,
                                "birthdate": this.birthDate,
                                "name": this.nameMother,
                                "document_issuer": null,
                                "phone": null,
                            }
                            this.isLoading = true;
                            await this.RegisterPatient(patientInfoMother)
                            const patientInfoChildren = {
                                "document": null,
                                "document_type": null,
                                "birthdate": this.birthDateChildren,
                                "name": this.nameChildren,
                                "document_issuer": null,
                                "phone": null,
                                "patient_id": this.Patient.id
                            }
                            await this.RegisterPatient(patientInfoChildren)
                            this.isLoading = false;
                            this.redirectAssistance();
                        }
                    }
                }
            }
        },
        async registerPatientAdultNotCPF() {
            {
                if (this.nameAdult == null || this.nameAdult == "") {
                    this.$toast.warning('Preencha o nome.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    if (!this.validateDate(this.birthDateAdult)) {
                        this.$toast.warning('Data de Nascimento Criança Incorreta.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                    else {
                        this.isLoading = true;
                        const patientInfoAdult = {
                            "document": null,
                            "document_type": null,
                            "birthdate": this.birthDateAdult,
                            "name": this.nameAdult,
                            "document_issuer": null,
                            "phone": null,
                        }
                        await this.RegisterPatient(patientInfoAdult)
                        this.isLoading = false;
                        this.redirectAssistance();
                    }
                }
            }
        },
        async registerPatientUnconscious() {
            {
                const patientInfoAdult = {
                    "document": null,
                    "document_type": null,
                    "birthdate": null,
                    "name": 'Paciente não identificado',
                }
                await this.RegisterPatient(patientInfoAdult)
                this.isLoading = false;
                this.redirectAssistance();
            }
        },
        async registryPatientAdultRG() {
            if (!this.validateRG(this.rg)) {
                this.$toast.warning('RG inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            } else {
                let patientInfo = {
                    "document": this.rg,
                    "document_issuer": this.document_issuer,
                    "document_type": "RG",
                    "birthdate": this.birthDate,
                    "name": this.name,
                }
                try {
                    await this.RegisterPatient(patientInfo)
                    this.redirectAssistance();
                }
                catch (erro) {
                    this.$toast.error('Erro ao cadastrar paciente.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                this.isLoading = false;
            }

        },
        async registryPatientAdultPassaporte() {
            if (!this.validatePassaport(this.passaporte)) {
                this.$toast.warning('Passaporte inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            } else {
                let patientInfo = {
                    "document": this.passaporte,
                    "document_issuer": null,
                    "document_type": "PASSAPORTE",
                    "birthdate": this.birthDate,
                    "name": this.name,
                }
                try {
                    await this.RegisterPatient(patientInfo)
                    this.redirectAssistance();
                }
                catch (erro) {
                    this.$toast.error('Erro ao cadastrar paciente.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                this.isLoading = false;
            }

        },



        async registerPatientNotCPF() {
            {
                if (this.nameChildren == null || this.nameChildren == "") {
                    this.$toast.warning('Preencha o nome da criança.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    if (!this.validateDate(this.birthDateChildren)) {
                        this.$toast.warning('Data de Nascimento Criança Incorreta.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                    else {
                        const patientInfoMother = {
                            "document": null,
                            "document_type": null,
                            "birthdate": this.birthDate,
                            "name": this.nameMother,
                            "document_issuer": null,
                            "phone": null,
                        }
                        this.isLoading = true;
                        await this.RegisterPatient(patientInfoMother)
                        const patientInfoChildren = {
                            "document": null,
                            "document_type": null,
                            "birthdate": this.birthDateChildren,
                            "name": this.nameChildren,
                            "document_issuer": null,
                            "phone": null,
                            "patient_id": this.Patient.id
                        }
                        await this.RegisterPatient(patientInfoChildren)
                        this.isLoading = false;
                        this.redirectAssistance();
                    }
                }
            }
        },
        redirectStep(step) {
            this.linkedList.add(step);
            this.nextItem();
        },
        calculateAge(dataNascimento) {
            if (dataNascimento !== null) {
                var partes = dataNascimento.split(' ');
                var dataPartes = partes[0].split('/');
                var horaPartes = partes.length > 1 ? partes[1].split(':') : [0, 0];
                var dataNasc = new Date(dataPartes[2], dataPartes[1] - 1, dataPartes[0], horaPartes[0], horaPartes[1]);
                var hoje = new Date();
                var idade = hoje.getFullYear() - dataNasc.getFullYear();
                var mes = hoje.getMonth() - dataNasc.getMonth();
                var dia = hoje.getDate() - dataNasc.getDate();
                if (mes < 0 || (mes === 0 && dia < 0)) {
                    idade--;
                }
                partes = dataNascimento.split(' ');
                var dataFormatada = partes[0]
                if (idade < 1) {
                    var meses = (hoje.getMonth() - dataNasc.getMonth()) + (12 * (hoje.getFullYear() - dataNasc.getFullYear()));
                    return dataFormatada + " (" + meses + " Meses)";
                } else {
                    return dataFormatada + " (" + idade + " Anos)";
                }
            }
        },
        validateCPF(cpf) {
            cpf = cpf.replace(/\D/g, '');
            if (cpf.length !== 11) return false;
            if (/^(\d)\1{10}$/.test(cpf)) return false;
            let sum = 0;
            let remainder;
            for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
            remainder = (sum * 10) % 11;
            if ((remainder === 10) || (remainder === 11)) remainder = 0;
            if (remainder !== parseInt(cpf.substring(9, 10))) return false;
            sum = 0;
            for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
            remainder = (sum * 10) % 11;
            if ((remainder === 10) || (remainder === 11)) remainder = 0;
            if (remainder !== parseInt(cpf.substring(10, 11))) return false;
            return true;
        },
        validateDate(data) {
            const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
            if (!regex.test(data)) {
                return false;
            }
            const partes = data.split('/');
            const dia = parseInt(partes[0], 10);
            const mes = parseInt(partes[1], 10) - 1;
            const ano = parseInt(partes[2], 10);
            const dataObjeto = new Date(ano, mes, dia);
            if (dataObjeto.getFullYear() !== ano || dataObjeto.getMonth() !== mes || dataObjeto.getDate() !== dia) {
                return false;
            }
            return true;
        },
        backDaily() {
            if (this.Specialty.type.name == "Teleinterconsulta") {
                this.$router.push("/");
            }
            else {
                this.$router.push("/daily");
            }
        },
        redirectAssistance() {
            this.$router.push("/assistance");
        },
        formatRG(rg) {
            return rg.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4");
        },
        validateRG(rg) {
            rg = rg.replace(/\D/g, '');
            if (rg.length < 7 || rg.length > 9) return false;
            if (/^(\d)\1+$/.test(rg)) return false;

            return true;
        },
        validatePassaport(passaporte) {
            passaporte = passaporte.replace(/[^A-Za-z0-9]/g, '');
            if (passaporte.length > 8){
                return false
            }else{
                return true
            }
        },
    }
};
</script>

<style scoped>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}

.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-field {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline-width: 0;
}

.label-text {
    position: absolute;
    top: -18px;
    left: 10px;
    font-size: 14px;
    background-color: #fff;
    padding: 0 5px;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.style-font-bold {
    color: #777777;
    font-weight: bold;
}

.style-font {
    color: #777777;
}

.button-back {
    background-color: #ffff;
    border: none;
}

.justified-text {
    color: #777777;
    text-align: justify;
}

@media screen and (max-width: 368px) {
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 80vh;
    }

    .content-container-up {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 120vh;
    }

    .flow-down {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px;
    }

    .justified-text {
        color: #777777;
        text-align: justify;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: normal;
        word-spacing: 0.01em;
    }

    .form-check-input {
        width: 30px;
    }

    .form-check-input-new {
        width: 60px;
    }
}

.attentive-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.btn-default {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6200EE;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-cancel {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-noborder {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-default:disabled {
    background-color: #6200EE;
    color: rgba(255, 255, 255, 0.7);
    cursor: not-allowed;
    border: 2px solid rgba(255, 255, 255, 0.5);
    opacity: 0.7;
}

.btn-default:hover:disabled {
    background-color: #6200EE;
    opacity: 0.7;
}
</style>
