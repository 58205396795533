<template>
    <div>
        <div class="container">
            <div v-if="isLoading === true">
                <div class="containermb-5">
                    <div class="row">
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div class="centered-container">
                                    <div class="loading"></div>
                                    <p class="text-font" style="margin-top: 10px; text-align: center">
                                        Carregando...
                                    </p>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <!-- Termo -->
                <div v-if="currentValue == 0">
                    <div class="row">
                        <div class="col-2">
                            <button class="button-back mx-3" @click="backLogin()">
                                <img src="../assets/back-button.png" alt="Button Image">
                            </button>
                        </div>
                        <div class="col-8">
                            <h2 class="style-font-bold">Termo de Consentimento</h2>
                        </div>
                        <div class="col-2"></div>
                    </div>
                    <div class="row mt-5">
                        <p class="justified-text">
                            Precisamos, inicialmente, do seu consentimento para que a Doc4Doc colete, armazene e utilize
                            os
                            dados inseridos na plataforma. Todas as informações cadastrais serão tratadas de acordo com
                            as
                            normas e políticas de proteção de dados pessoais do país, sempre com a finalidade de
                            realizar as
                            teleinterconsultas e de emitir relatórios médicos ao final dos atendimentos.
                        </p>
                        <p class="justified-text">
                            O solicitante concorda que os serviços prestados pela DOC4DOC devem ser utilizados
                            exclusivamente para atendimento dos pacientes que se encontrem em atendimento
                            pronto-socorro, na
                            enfermaria ou na UTI da instituição Contratante, ficando expressamente vedado o auxílio a
                            pacientes que não se encontrem nestas unidades, ainda que o médico solicitante componha o
                            corpo
                            clínico desta.
                        </p>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="form-check d-flex justify-content-center align-items-center">
                                <input type="checkbox" style="margin-right: 10px;" class="form-check-input"
                                    id="termsFormAssistence" v-model="termsFormAssistence">
                                <label class="form-check-label justified-text" for="termsFormAssistence">Li, concordo e
                                    aceito
                                    todos os termos e condições de uso.</label>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="form-check d-flex justify-content-center align-items-center">
                                <input type="checkbox" style="margin-right: 10px;" class="form-check-input"
                                    id="termsFormAssistence" v-model="institutionalMessages">
                                <label class="form-check-label aling-text" for="institutionalMessages">Mensagens
                                    institucionais (opcional)</label>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                    <div class="row flow-down" style="margin-bottom: 20px;">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="redirectStepInTerm">PRÓXIMO</button>
                        </div>
                    </div>
                </div>
                <!-- Dados Cadastro: Instituição -->
                <div v-if="currentValue == 1">
                    <div class="row">
                        <div class="col-2">
                            <button class="button-back mx-3" @click="previousItem()">
                                <img src="../assets/back-button.png" alt="Button Image">
                            </button>
                        </div>
                        <div class="col-10">
                            <h2 class="style-font-bold">Validação de instituição</h2>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <p class="justified-text">
                            Insira os dados da sua instituição nos campos abaixo. Em caso de dúvidas entre em contato
                            com a coordenação da sua área:
                        </p>
                    </div>
                    <div class="row mt-5">
                        <div class="input-container">
                            <label class="label-text justified-text" for="codeInstitute">Digite o código de ativação
                                institucional:</label>
                            <input class="input-field" type="text" v-model="codeInstitute" id="codeInstitute"
                                name="codeInstitute" maxlength="10" placeholder="Código de ativação institucional"
                                @input="validateFreq">
                        </div>
                    </div>
                    <div class="row mt-5">
                        <button class="btn-default-text" @click="redirectWhats()">NÃO SEI O CÓDIGO
                            INSTITUCIONAL</button>
                    </div>
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="validKey()">PRÓXIMO</button>
                        </div>
                        <div class="attentive-buttons" style="margin-top: 20px">
                            <button class="btn-cancel" @click="redirectStep(8)">CANCELAR</button>
                        </div>
                    </div>
                </div>
                <!-- Dados Cadastro: Whatsapp -->
                <div v-if="currentValue == 2">
                    <div class="row">
                        <div class="col-2">
                            <button class="button-back mx-3" @click="previousItem()">
                                <img src="../assets/back-button.png" alt="Button Image">
                            </button>
                        </div>
                        <div class="col-10">
                            <h2 class="style-font-bold">Número de WhatsApp</h2>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <p class="justified-text">
                            Digite abaixo seu número de WhatsApp:
                        </p>
                    </div>
                    <div class="row mt-5">
                        <div class="input-container">
                            <input class="input-field" type="text" v-model="phoneInput" id="phoneInput"
                                name="phoneInput" placeholder="(00) 00000-0000" v-mask="'(##) #####-####'">
                        </div>
                    </div>
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="createToken()">PRÓXIMO</button>
                        </div>
                        <div class="attentive-buttons" style="margin-top: 20px">
                            <button class="btn-cancel" @click="redirectStep(8)">CANCELAR</button>
                        </div>
                    </div>
                </div>
                <!-- Dados Cadastro: Token -->
                <div v-if="currentValue == 3">
                    <div class="container">
                        <div class="row">
                            <div class="col-2">
                                <button class="button-back mx-3" @click="previousItem()">
                                    <img src="../assets/back-button.png" alt="Button Image">
                                </button>
                            </div>
                            <div class="col-10">
                                <h2 class="style-font-bold">Digite o código de verificação:
                                </h2>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <p class="justified-text">
                                Enviamos um SMS com um código de 4 dígitos para: {{ this.phoneInput }}. <span
                                    style="margin-left: 2px;" @click="previousItem" class="resend-link">Alterar</span>
                            </p>
                        </div>
                        <div class="row mt-5">
                            <div class="token-input-container">
                                <input class="token-input style-font" type="text" v-model="token[0]" maxlength="1"
                                    @input="focusNext($event, 1)" ref="input0">
                                <input class="token-input style-font" type="text" v-model="token[1]" maxlength="1"
                                    @input="focusNext($event, 2)" ref="input1">
                                <input class="token-input style-font" type="text" v-model="token[2]" maxlength="1"
                                    @input="focusNext($event, 3)" ref="input2">
                                <input class="token-input style-font" type="text" v-model="token[3]" maxlength="1"
                                    @input="focusNext($event, 4)" ref="input3">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="resend-container">
                                <p class="justified-text centered-text" v-if="countdown > 0">Não recebeu? Reenviar
                                    código em
                                    {{ formattedCountdown }}</p>
                                <span v-else @click="resendCode()" class="resend-link">Reenviar código</span>
                            </div>
                        </div>
                        <div class="row flow-down">
                            <div class="attentive-buttons">
                                <button class="btn-default" @click="validateToken()">PRÓXIMO</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Dados Cadastro: Identificação Profissional -->
                <div v-if="currentValue == 4">
                    <div class="row">
                        <div class="col-2">
                            <button class="button-back mx-3" @click="previousItem()">
                                <img src="../assets/back-button.png" alt="Button Image">
                            </button>
                        </div>
                        <div class="col-10">
                            <h2 class="style-font-bold">Selecione uma das opções abaixo:</h2>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <p class="justified-text">
                            Qual é a sua profissão?
                        </p>
                    </div>
                    <div class="row mt-3" style="cursor: pointer">
                        <div class="border-in" @click="redirectMedico()" style="display: flex; align-items: center;">
                            <img src="../assets/doctor.png" style="margin-right: 10px;">
                            <div style="display: flex; flex-direction: column;">
                                <h4 class="style-font" style="margin: 0;">Médico(a)</h4>
                                <p class="style-font" style="margin: 0;">Mantenha o número do CRM à mão</p>
                            </div>
                            <div style="display: flex; align-items: center; margin-left: auto;">
                                <font-awesome-icon :icon="['fas', 'chevron-right']" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5" style="cursor: pointer">
                        <div class="border-in" @click="redirectEnfermeiro()"
                            style="display: flex; align-items: center;">
                            <img src="../assets/nurse.png" style="margin-right: 10px;">
                            <div style="display: flex; flex-direction: column;">
                                <h4 class="style-font" style="margin: 0;">Enfermeiro(a)</h4>
                                <p class="style-font" style="margin: 0;">Mantenha o número do COREM à mão</p>
                            </div>
                            <div style="display: flex; align-items: center; margin-left: auto;">
                                <font-awesome-icon :icon="['fas', 'chevron-right']" />
                            </div>
                        </div>
                    </div>
                    <div class="row flow-down">
                        <div class="attentive-buttons" style="margin-top: 20px">
                            <button class="btn-cancel" @click="redirectStep(8)">CANCELAR</button>
                        </div>
                    </div>
                </div>
                <!-- Dados Cadastro: Validação Informações Enfermeiro-->
                <div v-if="currentValue == 5">
                    <div class="row">
                        <div class="col-2">
                            <button class="button-back mx-3" @click="previousItem()">
                                <img src="../assets/back-button.png" alt="Button Image">
                            </button>
                        </div>
                        <div class="col-10">
                            <h2 class="style-font-bold">Validação de identidade</h2>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="input-container">
                            <label class="label-text justified-text" for="cpf">Digite abaixo seu CPF:</label>
                            <input class="input-field" type="text" v-model="cpf" id="cpf" name="cpf" maxlength="14"
                                placeholder="000.000.000-00">
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="input-container">
                            <label class="label-text justified-text" for="birthDate">Digite abaixo sua data de
                                nascimento:</label>
                            <input class="input-field" type="text" v-model="birthDate" id="birthDate" name="birthDate"
                                maxlength="10" placeholder="00/00/0000" v-mask="'##/##/####'">
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="input-container">
                            <label class="label-text justified-text" for="codeProfessional">Digite abaixo seu número de
                                COREM:</label>
                            <input class="input-field" type="text" v-model="codeProfessional" id="codeProfessional"
                                name="codeProfessional" maxlength="7" placeholder="0000000">
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="input-container">
                            <label class="label-text justified-text" for="selectedState">Selecione a UF do
                                documento:</label>
                            <v-select class="mt-2" v-model="selectedState" :options="states" label="name" id="state">
                                <template v-slot:no-options>
                                    <span>Nenhum estado encontrado</span>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="registerHealthProfessional('COREN')">PRÓXIMO</button>
                        </div>
                        <div class="attentive-buttons" style="margin-top: 20px">
                            <button class="btn-cancel" @click="redirectStep(8)">CANCELAR</button>
                        </div>
                    </div>
                </div>
                <!-- Dados Cadastro: Validação Informações Medico-->
                <div v-if="currentValue == 6">
                    <div class="row">
                        <div class="col-2">
                            <button class="button-back mx-3" @click="previousItem()">
                                <img src="../assets/back-button.png" alt="Button Image">
                            </button>
                        </div>
                        <div class="col-10">
                            <h2 class="style-font-bold">Validação de identidade</h2>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="input-container">
                            <label class="label-text justified-text" for="cpf">Digite abaixo seu CPF:</label>
                            <input class="input-field" type="text" v-model="cpf" id="cpf" name="cpf" maxlength="14"
                                placeholder="000.000.000-00" @input="validateFreq">
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="input-container">
                            <label class="label-text justified-text" for="birthDate">Digite abaixo sua data de
                                nascimento:</label>
                            <input class="input-field" type="text" v-model="birthDate" id="birthDate" name="birthDate"
                                maxlength="10" placeholder="00/00/0000" v-mask="'##/##/####'">
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="input-container">
                            <label class="label-text justified-text" for="codeProfessional">Digite abaixo seu número de
                                CRM:</label>
                            <input class="input-field" type="text" v-model="codeProfessional" id="codeProfessional"
                                name="codeProfessional" maxlength="5" placeholder="0000000">
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="input-container">
                            <label class="label-text justified-text" for="selectedState">Selecione a UF do
                                documento:</label>
                            <v-select class="mt-2" v-model="selectedState" :options="states" label="name" id="state">
                                <template v-slot:no-options>
                                    <span>Nenhum estado encontrado</span>
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div class="row flow-down">
                        <div class="attentive-buttons">
                            <button class="btn-default" @click="registerHealthProfessional('CRM')">PRÓXIMO</button>
                        </div>
                        <div class="attentive-buttons" style="margin-top: 20px">
                            <button class="btn-cancel" @click="redirectStep(8)">CANCELAR</button>
                        </div>
                    </div>
                </div>
                <!-- Dados Cadastro: Completo -->
                <div v-if="currentValue == 7">
                    <div class="row">
                        <div class="col-2">
                            <button class="button-back mx-3" @click="previousItem()">
                                <img src="../assets/back-button.png" alt="Button Image">
                            </button>
                        </div>
                        <div class="col-10">
                            <h2 class="style-font-bold">Cadastro completo</h2>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-4 d-flex justify-content-center">
                            <img src="../assets/ic_register_ok.png" class="img-fluid" width="300" height="300">
                        </div>
                    </div>
                    <div class="content-container">
                        <div class="row mt-5">
                            <p class="center-text justified-text">
                                Acesse nossa plataforma de solicitação agora clicando no botão abaixo.
                            </p>
                        </div>
                        <div style="margin-top: 80px;"></div>
                        <div class="row flow-down">
                            <div class="attentive-buttons">
                                <button class="btn-default" @click="backLogin()">IR PARA TELA DE ENTRADA</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Cancelar Jornada -->
                <div v-if="currentValue == 8">
                    <div class="row">
                        <div class="col-2">
                            <button class="button-back mx-3" @click="previousItem()">
                                <img src="../assets/back-button.png" alt="Button Image">
                            </button>
                        </div>
                        <div class="col-10">
                            <h2 class="style-font-bold">Cancelar</h2>
                        </div>
                    </div>
                    <div class="row mt-3 justify-content-center">
                        <div class="col-12 col-md-4 d-flex justify-content-center">
                            <img src="../assets/cancel.png" class="img-fluid" width="300" height="300">
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12 text-center">
                            <p style="color: #6200EE;"><b>Confirmação</b></p>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 text-center">
                            <p style="color: #777777;">Tem certeza de deseja cancelar a jornada?</p>
                        </div>
                    </div>
                    <div class="content-container">
                        <div class="row flow-down">
                            <div class="attentive-buttons">
                                <button class="btn-default" @click="previousItem()">CONTINUAR AQUI</button>
                            </div>
                            <div class="attentive-buttons" style="margin-top: 20px">
                                <button class="btn-cancel" @click="backLogin">SAIR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
class Node {
    constructor(data) {
        this.data = data;
        this.previous = null;
        this.next = null;
    }
}

class LinkedList {
    constructor() {
        this.head = null;
        this.tail = null;
    }

    add(data) {
        const newNode = new Node(data);
        if (!this.head) {
            this.head = newNode;
            this.tail = newNode;
        } else {
            this.tail.next = newNode;
            newNode.previous = this.tail;
            this.tail = newNode;
        }
    }

    removeLast() {
        if (!this.tail) {
            return;
        }
        if (this.head === this.tail) {
            this.head = null;
            this.tail = null;
            return;
        }
        this.tail = this.tail.previous;
        this.tail.next = null;
    }

    toArrayWithNeighbors() {
        let current = this.head;
        const array = [];
        while (current) {
            const item = {
                value: current.data,
                previous: current.previous ? current.previous.data : null,
                next: current.next ? current.next.data : null
            };
            array.push(item);
            current = current.next;
        }
        return array;
    }
}

import { mapActions } from "vuex";

export default {
    name: `Register`,
    mounted() {
        this.termsFormAssistence = false;
        this.institutionalMessages = false;
    },
    async created() {
        this.phoneInput = this.phone;
        this.termsFormAssistence = false;
        this.institutionalMessages = false;
        this.linkedList.add(0);//Termo
        this.currentValue = 0;
    },
    data() {
        return {
            isLoading: false,
            linkedList: new LinkedList(),
            currentItem: 0,
            currentObject: null,
            currentValue: 0,
            phoneDefault: null,
            token: ['', '', '', ''],
            countdown: 59,
            cpf: "",
            birthDate: "",
            codeProfessional: "",
            termsFormAssistence: false,
            institutionalMessages: false,
            codeInstitute: "",
            company: null,
            phoneInput: "",
            authPin: null,
            selectedState: "",
            states: [
                { code: 'AC', name: 'Acre' },
                { code: 'AL', name: 'Alagoas' },
                { code: 'AP', name: 'Amapá' },
                { code: 'AM', name: 'Amazonas' },
                { code: 'BA', name: 'Bahia' },
                { code: 'CE', name: 'Ceará' },
                { code: 'DF', name: 'Distrito Federal' },
                { code: 'ES', name: 'Espírito Santo' },
                { code: 'GO', name: 'Goiás' },
                { code: 'MA', name: 'Maranhão' },
                { code: 'MT', name: 'Mato Grosso' },
                { code: 'MS', name: 'Mato Grosso do Sul' },
                { code: 'MG', name: 'Minas Gerais' },
                { code: 'PA', name: 'Pará' },
                { code: 'PB', name: 'Paraíba' },
                { code: 'PR', name: 'Paraná' },
                { code: 'PE', name: 'Pernambuco' },
                { code: 'PI', name: 'Piauí' },
                { code: 'RJ', name: 'Rio de Janeiro' },
                { code: 'RN', name: 'Rio Grande do Norte' },
                { code: 'RS', name: 'Rio Grande do Sul' },
                { code: 'RO', name: 'Rondônia' },
                { code: 'RR', name: 'Roraima' },
                { code: 'SC', name: 'Santa Catarina' },
                { code: 'SP', name: 'São Paulo' },
                { code: 'SE', name: 'Sergipe' },
                { code: 'TO', name: 'Tocantins' },
            ],
        }
    },
    computed: {
        formattedCountdown() {
            const minutes = Math.floor(this.countdown / 60);
            const seconds = this.countdown % 60;
            return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },
        phone() {
            return this.$route.query.phone || '';
        }
    },
    methods: {
        ...mapActions(["ValidationDocumentNotToken"]),
        ...mapActions(["GeneratePin"]),
        ...mapActions(["ValidKeyInstitute"]),
        ...mapActions(["CreatePin"]),
        ...mapActions(["ValidatePin"]),
        ...mapActions(["RegisterComplete"]),
        redirectMedico() {
            this.cpf = "",
                this.birthDate = "",
                this.codeProfessional = "",
                this.selectedState = null
            this.redirectStep(6);
        },
        redirectEnfermeiro() {
            this.cpf = "",
                this.birthDate = "",
                this.codeProfessional = "",
                this.selectedState = null
            this.redirectStep(5);
        },
        redirectStepInTerm() {
            if (this.termsFormAssistence == false || this.termsFormAssistence == null) {
                this.$toast.warning('É preciso aceitar os Termos de consentimento para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.redirectStep(1);
            }
        },
        async registerHealthProfessional(type) {
            var info = {
                "professional_code": this.codeProfessional,
                "state_code": this.selectedState.code,
                "health_professional_type": type,
                "phone": "55" + this.phoneInput.replace(/[()\s-]/g, ''),
                "pin": this.authPin.pin,
                "document_type": "CPF",
                "document": this.cpf.replace(/[.-]/g, ''),
                "company_code": this.company.code,
                "birthdate": this.birthDate
            }
            var result = await this.RegisterComplete(info);
            if (result.status == 200) {
                this.redirectStep(7);
            }
            else {                
                if (result.error.data.error.message.replace(" ", "") == "invalid_inputs") {
                    this.$toast.warning("CPF Inválido", {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    this.$toast.warning(result.error.data.error.message, {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
            }
        },
        async createToken() {
            if (this.phoneInput == null || this.phoneInput == "" || this.phoneInput.length < 13) {
                this.$toast.warning('Informe um número de telefone.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.isLoading = true;
                try {
                    await this.CreatePin(this.phoneInput.replace(/[()\s-]/g, ''));
                    this.redirectStep(3);
                    this.startCountdown();
                }
                catch (erro) {
                    console.log(erro)
                    if (erro.response.data.error.message == "invalid_inputs") {
                        this.$toast.error('Número já cadastrado ou incorreto.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                    else {
                        this.$toast.error('Ocorreu um erro ao gerar o Pin.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                }
                this.isLoading = false;
            }
        },
        async validateToken() {
            this.isLoading = true;
            this.authPin = await this.ValidatePin({
                "phone": "55" + this.phoneInput.replace(/[()\s-]/g, ''),
                "pin": this.token.join('')
            })
            if (this.authPin != null) {
                this.redirectStep(4);
            }
            else {
                this.$toast.warning('Pin Incorreto.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            this.isLoading = false;
        },
        async validKey() {
            this.isLoading = true;
            this.company = await this.ValidKeyInstitute(this.codeInstitute);
            if (this.company != undefined) {
                this.redirectStep(2);
            }
            else {
                this.$toast.error('É preciso informar um código de ativação válido para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            this.isLoading = false;
        },
        previousItem() {
            if (this.currentItem > 0) {
                this.currentItem--;
                this.currentObject = this.linkedList.toArrayWithNeighbors()[this.currentItem];
                this.currentValue = this.currentObject.value
                this.linkedList.removeLast();
            }
        },
        nextItem() {
            const linkedListLength = this.linkedList.toArrayWithNeighbors().length;
            if (this.currentItem < linkedListLength - 1) {
                this.currentItem++;
                this.currentObject = this.linkedList.toArrayWithNeighbors()[this.currentItem];
                this.currentValue = this.currentObject.value
            }
        },
        redirectStep(step) {
            this.linkedList.add(step);
            this.nextItem();
        },
        backLogin() {
            this.$router.push("/login");
        },
        async resendCode() {
            this.isLoading = true
            try {
                await this.CreatePin(this.phone.replace(/[()\s-]/g, ''));
                this.countdown = 59;
                this.startCountdown();
            }
            catch (erro) {
                this.$toast.error('Ocorreu um erro ao gerar o Pin.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            this.isLoading = false;
        },
        startCountdown() {
            this.interval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.interval);
                }
            }, 1000);
        },
        focusNext(event, nextIndex) {
            if (event.target.value.length === 1 && nextIndex < 4) {
                this.$refs[`input${nextIndex}`].focus();
            }
        },
        redirectWhats() {
            window.open("https://api.whatsapp.com/send/?phone=5534998391963&text=Ol%C3%A1,+preciso+de+suporte+na+minha+jornada", "_blank");
        },
        validateCPF(cpf) {
            cpf = cpf.replace(/\D/g, '');
            if (cpf.length !== 11) return false;
            if (/^(\d)\1{10}$/.test(cpf)) return false;
            let sum = 0;
            let remainder;
            for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
            remainder = (sum * 10) % 11;
            if ((remainder === 10) || (remainder === 11)) remainder = 0;
            if (remainder !== parseInt(cpf.substring(9, 10))) return false;
            sum = 0;
            for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
            remainder = (sum * 10) % 11;
            if ((remainder === 10) || (remainder === 11)) remainder = 0;
            if (remainder !== parseInt(cpf.substring(10, 11))) return false;
            return true;
        },
        validateDate(data) {
            const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
            if (!regex.test(data)) {
                return false;
            }
            const partes = data.split('/');
            const dia = parseInt(partes[0], 10);
            const mes = parseInt(partes[1], 10) - 1;
            const ano = parseInt(partes[2], 10);
            const dataObjeto = new Date(ano, mes, dia);
            if (dataObjeto.getFullYear() !== ano || dataObjeto.getMonth() !== mes || dataObjeto.getDate() !== dia) {
                return false;
            }
            return true;
        },
        async registryUserValidationCPF() {
            if (!this.validateCPF(this.cpf)) {
                this.$toast.warning('CPF inválido.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (!this.validateDate(this.birthDate)) {
                    this.$toast.warning('Data de Nascimento Incorreta.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    let patientData = {
                        "cpf": this.cpf,
                        "birthDate": this.birthDate
                    }
                    this.isLoading = true;
                    let result = await this.ValidationDocumentNotToken(patientData);
                    this.isLoading = false;
                    if (result) {
                        const dateAPI = new Date(result.birthdate);
                        const [day, month, year] = this.birthDate.split('/');
                        const dateForm = new Date(`${year}-${month}-${day}`);
                        const dateAPIStr = dateAPI.toISOString().split('T')[0];
                        const dateFormStr = dateForm.toISOString().split('T')[0];
                        if (dateAPIStr !== dateFormStr) {
                            this.$toast.warning('Data de Nascimento Incorreta.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                        else {
                            this.redirectStep(5)
                        }
                    }
                    else {
                        this.$toast.warning('Dados incorretos.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                }
            }
        },
    }
}
</script>

<style scoped>
@import "vue-select/dist/vue-select.css";

:deep(.v-select) {
    --vs-dropdown-max-height: 200px;
    --vs-border-width: 2px;
    --vs-border-style: solid;
    --vs-border-radius: 25px;
    --vs-border-color: #CCCCCC;
    --vs-dropdown-color: #777777;
    --vs-line-height: 2.0;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.center-text {
    text-align: center;
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}

.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-field {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline-width: 0;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}


.style-font-bold {
    color: #777777;
    font-weight: bold;
}

.style-font {
    color: #777777;
}

.button-back {
    background-color: #ffff;
    border: none;
}

.aling-text {
    color: #777777;
    font-size: 14px;
}

.justified-text {
    color: #777777;
    text-align: justify;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: normal;
    word-spacing: 0.01em;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.flex-container {
    align-items: center;
}

@media screen and (max-width: 368px) {
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 80vh;
        max-width: 100%;
        padding: 0 10px;
    }

    .flex-container {
        align-items: center;
    }

    .content-container-up {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 120vh;
    }

    .flow-down {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px;
    }

    .justified-text {
        color: #777777;
        text-align: justify;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: normal;
        word-spacing: 0.01em;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    .form-check-input {
        width: 30px;
    }
}

.attentive-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.btn-default-text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200EE;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    border: none;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-default {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6200EE;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-cancel {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-noborder {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.custom-select {
    border: 2px solid #CCCCCC;
    border-radius: 25px;
    padding: 5px;
    font-size: 16px;
    color: #777777;
    background-color: white;
}

.custom-select option {
    text-align: center;
}

.custom-select:focus {
    outline: none;
    border-color: #777777;
}
</style>

<style scoped>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.resend-link {
    color: #6200EE;
    cursor: pointer;
    text-decoration: underline;
}

.token-input-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.token-input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-field {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline-width: 0;
}

.label-text {
    position: absolute;
    top: -18px;
    left: 10px;
    font-size: 14px;
    background-color: #fff;
    padding: 0 5px;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.style-font-bold {
    color: #777777;
    font-weight: bold;
}

.style-font {
    color: #777777;
}

.button-back {
    background-color: #ffff;
    border: none;
}

.justified-text {
    color: #777777;
    text-align: justify;
}

.resend-container {
    display: flex;
    justify-content: center;
    align-items: center;

}

.centered-text {
    margin: 0 auto;
}

@media screen and (max-width: 368px) {
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 80vh;
    }

    .content-container-up {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 120vh;
    }

    .flow-down {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px;
    }

    .justified-text {
        color: #777777;
        text-align: justify;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: normal;
        word-spacing: 0.01em;
    }

    .form-check-input {
        width: 40px;
    }
}

.attentive-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.btn-default {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6200EE;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-cancel {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-noborder {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}
</style>
