export default {
    user: null,
    dailyCases: null,
    evolutionCases: null,
    generalCases: null,
    reports: null,
    evolvedPatientsToday: null,
    patient: null,
    patientMother: null,
    protocol: null,
    responsible: null,
    childrens: null,
    outcome: null,
    specialty: null,
    urlReport: null,
    form: {
        error: null
    },
    version: '1.0',
}
