<template>
    <div class="col-12">
        <div class="mt-5 mb-5">
            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-8">
                    <div class="d-flex align-items-center">
                        <button class="button-back" @click="backReport()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                        <h2 class="texto-regular-bold text-center fw-bold" style="color: #333232">Histórico do caso</h2>
                    </div>
                    <div v-if="isLoading === true">
                        <div class="conteiner mt-5 mb-5">
                            <div class="row mt-5">
                                <div class="row mt-4">
                                    <div class="col-2"></div>
                                    <div class="col-8">
                                        <div class="centered-container">
                                            <div class="loading"></div>
                                        </div>
                                        <p class="text-font-regular" style="margin-top: 10px; text-align: center">
                                            Carregando...
                                        </p>
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="Report !== null">
                            <div class="mt-4" style="background-color: #f0f0f0; padding:8px;">
                                <div class="container-info">
                                    <h5 class="texto-bold" style="color: #333232">ESPECIALIDADE:
                                        {{ Report.protocol_update_info.protocol_info.specialty.toUpperCase() }} </h5>
                                </div>
                                <div class="container-info">
                                    <h5 class="textobold" style="color: #333232">Paciente:
                                        {{ formattedPatientName(Report.protocol_update_info.protocol_info.patient_info.name) }}</h5>
                                </div>
                            </div>

                            <div class="attentive-buttons">
                                <button class="btn-review" @click="downloadLaudoHistoric()">BAIXAR LAUDO</button>
                            </div>

                            <div class="mt-5">
                                <h5 class="texto-bold" style="color: #777777">Resumo:</h5>
                            </div>

                            <div class="mt-4">
                                <b>Febre:</b> {{Report.protocol_update_info.patient_fever == null ? 'Não' : 'Sim'}}
                            </div>

                            <div class="mt-3">
                                <b>Glicemia:</b> {{Report.protocol_update_info.patient_glycemia == null ? 'Glicemia não apresenta variações' : Report.protocol_update_info.patient_glycemia}}
                            </div>

                            <div class="mt-3">
                                <b>Balanço hídrico:</b> {{Report.protocol_update_info.patient_balance == null ? 'Sem informação' : Report.protocol_update_info.patient_balance}}
                            </div>

                            <div class="mt-3">
                                <b>Ventilação mecânica:</b> {{Report.protocol_update_info.patient_air_support == null ? 'Não' : Report.protocol_update_info.patient_air_support}}
                            </div>

                            <div class="mt-3">
                                <b>Tipo de respiração:</b> {{Report.protocol_update_info.patient_ventilation_type == null ? 'Sem informação' : Report.protocol_update_info.patient_ventilation_type}}
                            </div>

                            <div class="mt-3">
                                <b>Drogas vasoativa:</b> {{Report.protocol_update_info.patient_vasoactive == null ? 'Sem informação' : Report.protocol_update_info.patient_vasoactive}}
                            </div>

                            <div class="mt-3">
                                <b>Quadro clínico:</b> {{Report.protocol_update_info.note == null ? 'Sem informação' : Report.protocol_update_info.note}}
                            </div>

                            <div v-for="file in Report.protocol_update_info.files" :key="file.id">
                                <div class="mt-3" >
                                    <b>Tipo do arquivo:</b> {{ file.extension }}
                                    <b>Nome:</b> {{ file.file_name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-2"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: `Review`,
    components: {

    },
    computed: {
        ...mapGetters({
            Report: "StateReport",
            User: "StateUser",
            UrlReport: "StateUrlReport",
        }),
    },
    data() {
        return {
            isLoading: true,
            protocolInfo: Object,
            typeSteps: "",
            reportId: null,
        };
    },
    async created() {
        this.reportValue = this.Report
        if (!this.isAuthenticated()) {
            this.$router.push("/login");
        }
        if (this.Report === null) {
            this.reportId = JSON.parse(localStorage.getItem('reportId'));
            if (this.reportId != null) {
                await this.GetReport(this.reportId);
                localStorage.removeItem('reportId');
                this.isLoading = false;
            }
            else {
                this.$router.push("/historical");
            }
        }
    },
    async mounted() {
        try {
            if (this.Report !== null) {
                this.isLoading = false;
            }
        }
        catch {
            this.$toast.error('Ocorreu um erro, tente novamente.', {
                timeout: 3000,
                closeOnClick: true
            });
        }
    },
    methods: {
        ...mapActions(["SetReport"]),
        ...mapGetters(["isAuthenticated"]),
        ...mapActions(["GetReport"]),
        ...mapActions(["GenerateReport"]),
        ...mapActions(["GetReportById"]),
        backReport() {
            this.$router.push('/historycompany');
        },
        alterarLoading(valueLoading) {
            this.isLoading = valueLoading;
        },
        async downloadLaudoHistoric() {
            try {
                await this.GenerateReport(this.Report)
                const pdfUrl = this.UrlReport;
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            catch {
                this.$toast.error('Ocorreu um erro, tente novamente.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
        },
        formatRamsay(number) {
            return "Ramsay " + number
        },
        formatCPF(cpf) {
            // Remove tudo que não for dígito
            cpf = cpf.replace(/\D/g, '');

            // Aplica a máscara de CPF
            return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        },
        formattedPatientName(name) {
            return name.split(' ') // Divide o nome por espaços
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitaliza cada palavra
                .join(' '); // Junta tudo de volta com espaços
        },
    },
};
</script>

<style>
.button-back {
    background-color: #ffff;
    border: none;
}

.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #6200EE;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
}

.button-image {
    width: 20px;
    height: 20px;

}

.attentive-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.texto-regular-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5px;
}

.separate {
    display: inline-block;
    align-self: stretch;
    width: 2px;
    min-height: 1em;
    opacity: 0.25;
}

.btn-transfer {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200EE;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: none;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-back-queue {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200EE;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-review {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6200EE;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.texto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
}

.container-info {
    display: flex;
}

.button-copy {
    padding: 0;
    border: none;
    background: none;
}

.text-font-copy {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
}

.separate {
    display: inline-block;
    align-self: stretch;
    width: 2px;
    min-height: 1em;
    opacity: 0.25;
}

.container-tittle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagem-tittle {
    width: 35px;
    height: 35px;
}

.container-info {
    display: flex;
}

.texto-tittle {
    text-align: center;
    margin-top: 15px;
    margin-left: 10px;
    padding: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
}

.texto-institution {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    margin-top: 10px;
    color: #777777;
}

.texto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
}

.texto-regular-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5px;
}

.texto-hospital {
    text-align: center;
    margin-left: 10px;
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #777777;
}

.btn-back {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6200EE;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 100px;
    cursor: pointer;
    margin: 0 auto;
}

.attentive-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: #666666;
    cursor: pointer;
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
