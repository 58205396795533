<template>
    <div>
        <input type="file" ref="fileInput" @change="handleFileUpload" accept="image/*,video/*,.pdf"
            style="display: none" />
        <div v-if="isLoading" class="centered-container">
            <div class="containermb-5">
                <div class="row">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <div class="centered-container">
                                <div class="loading"></div>
                                <p class="text-font" style="margin-top: 10px; text-align: center">
                                    Carregando...
                                </p>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="selectedStep">
                <!-- TITULO -->
                <div class="row">
                    <div class="col-2 d-none d-md-block">
                    </div>
                    <div class="col-2 col-md-1">
                        <button class="button-back mx-3" @click="previousStep()">
                            <img src="../assets/back-button.png" alt="Button Image">
                        </button>
                    </div>
                    <div class="col-10 col-md-8">
                        <h2 class="style-font-bold">{{ selectedStep.title }}</h2>
                    </div>
                </div>
                <div v-if="selectedStep.showInfoPatient != '' &&
                    selectedStep.showInfoPatient != undefined &&
                    selectedStep.showInfoPatient != null">
                    <div class="row">
                        <div class="col-2">
                        </div>
                        <div class="col-8">
                            <div style="background-color: #F2F2F2;">
                                <p style="margin-top: 15px;" class="justified-text">
                                    Evolução de Paciente
                                </p>
                                <p class="justified-text">
                                    {{ replaceVariables(selectedStep.showInfoPatient) }}
                                </p>
                            </div>
                        </div>
                        <div class="col-2"></div>
                    </div>
                </div>
                <!-- CONTEUDO -->
                <div class="mt-3" v-for="(item, index) in selectedStep.content" :key="index">
                    <div v-if="item.type == 'image'">
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div class="row mt-3 justify-content-center">
                                    <div class="col-12 col-md-4 d-flex justify-content-center">
                                        <img :src="`/assets/${item.img}`" class="img-fluid" width="300" height="300">
                                    </div>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    <div v-if="item.type == 'text'">
                        <div class="row">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <p class="justified-text" v-html="replaceVariables(item.value)">
                                </p>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    <div v-if="item.type == 'checkbox'" :key="item.model">
                        <div class="row mt-2">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div class="form-check d-flex justify-content-center align-items-center">
                                    <input type="checkbox" style="margin-right: 10px;" class="form-check-input"
                                        id=item.model v-model="propertys[item.model]">
                                    <label class="form-check-label justified-text" for=item.model>{{ item.label
                                        }}</label>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    <div v-if="item.type == 'input'" :key="item.model">
                        <div class="row mt-2">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div class="input-container">
                                    <label class="label-text justified-text" :for="item.model">{{ item.label }}</label>
                                    <input class="input-field" :type="item.ref" v-model="propertys[item.model]"
                                        :id="item.model" :name="item.model" :maxlength="item.maxlength"
                                        :placeholder="item.placeholder" :v-mask="item.mask"
                                        @input="executeAction(item.action, $event, item.model)">
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    <div v-if="item.type == 'select'" :key="item.model">
                        <div class="row mt-2">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div class="input-container">
                                    <label class="label-text justified-text" for="selectedState">{{ item.label
                                        }}</label>
                                    <v-select class="mt-2" v-model="propertys[item.model]"
                                        :options="propertys[item.options]" label="name" id="state">
                                        <template v-slot:no-options>
                                            <span>{{ item.empty }}</span>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    <div v-if="item.type == 'button'" :key="item.model">
                        <div class="row mt-3" style="cursor: pointer">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div class="border-in"
                                    @click="executeAction(item.action, item.value, item.model, item.nextStep)"
                                    style="display: flex; align-items: center;">
                                    <img :src="`/assets/${item.img}`" style="margin-right: 10px;">
                                    <div style="display: flex; flex-direction: column;">
                                        <h4 class="style-font" style="margin: 0;" v-html="item.label"></h4>
                                        <p class="style-font" style="margin: 0;">{{ item.description }}</p>
                                    </div>
                                    <div style="display: flex; align-items: center; margin-left: auto;">
                                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    <div v-if="item.type == 'listCheckbox'" :key="item.model">
                        <div class="row mt-2">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div v-for="(option, index) in item.value" :key="index" class="row mt-3"
                                    style="cursor: pointer">
                                    <div class="border-in" style="display: flex; align-items: center;">
                                        <div style="display: flex; align-items: center; flex-direction: column;">
                                            <div style="display: flex; align-items: center;">
                                                <input type="checkbox" :id="option.id" :value="option.label"
                                                    v-model="propertys[item.model]" />
                                                <label :for="option.id" class="style-font" style="margin-left: 10px;">{{
                                                    option.label
                                                    }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    <div v-if="item.type == 'textArea'" :key="item.model">
                        <div class="row mt-2">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div class="input-container">
                                    <textarea class="input-field" v-model="propertys[item.model]" :id="item.model"
                                        :name="item.model" :rows="item.rows" :placeholder="item.placeholder"></textarea>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    <div v-if="item.type == 'upload'" :key="item.model">
                        <div class="row mt-2">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div v-for="(file, index) in propertys[item.model]" :key="index" class="file-info"
                                    style="display: flex; align-items: center; justify-content: space-between;">
                                    <div style="display: flex; align-items: center;">
                                        <img :src="`/assets/${item.imgAdd}`" alt="Ícone de Sucesso"
                                            style="width: 36px; height: 36px;" class="success-icon" />
                                        <div class="file-details"
                                            style="margin-left: 10px; display: flex; align-items: center; justify-content: space-between; flex-direction: row; width: 100%;">
                                            <div style="flex-grow: 1; margin-top: 15px;">
                                                <p class="justified-text">
                                                    <strong>Tipo de arquivo:</strong> {{
                                                        file.name.split('.').pop().toLowerCase() }}
                                                </p>
                                                <p class="justified-text">
                                                    <strong>Nome:</strong> {{ file.name.substring(0,
                                                        file.name.lastIndexOf('.')) }}
                                                </p>
                                            </div>
                                            <div style="margin-left: 10px;">
                                                <img :src="`/assets/${item.imgRemove}`" alt="Ícone de Excluir"
                                                    class="delete-icon"
                                                    style="cursor: pointer; width: 18px; height: 18px; margin-left: auto;"
                                                    @click="executeAction(item.removeAction, index)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1" style="cursor: pointer">
                                    <div class="attentive-buttons" style="margin-top: 20px">
                                        <button class="btn-cancel" @click="executeAction(item.addAction)">
                                            <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']"
                                                class="icon-left" style="margin-right: 8px;" />
                                            ADICIONAR ARQUIVO
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    <div v-if="item.type == 'resume'" :key="item.model">

                        <div class="row mt-2">
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div v-for="(resumeItem, index) in propertys['resumePatient']" :key="index">
                                    <div v-if="resumeItem.type === 'Boolean' && propertys[resumeItem.property] != null">
                                        <div class="flex-container">
                                            <p style="color: #777777;"><b>{{ resumeItem.label }}: </b></p>
                                            <p class="justified-text" style="margin-left: 5px;">
                                                {{ renderTrueFalseText(propertys[resumeItem.property]) }}
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        v-else-if="resumeItem.type === 'String' && propertys[resumeItem.property] != null && propertys[resumeItem.property] !== ''">
                                        <div class="flex-container">
                                            <p style="color: #777777;"><b>{{ resumeItem.label }}: </b></p>
                                            <p class="justified-text" style="margin-left: 5px;">
                                                {{ propertys[resumeItem.property] }}
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        v-else-if="resumeItem.type === 'Array' && propertys[resumeItem.property].length > 0">
                                        <div class="flex-container">
                                            <p style="color: #777777;"><b>{{ resumeItem.label }}: </b></p>
                                            <div v-for="(itemArray, index) in propertys[resumeItem.property]"
                                                :key="index">
                                                <p class="justified-text">{{ itemArray }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                </div>
                <!-- BOTOES -->
                <div class="row flow-down mt-4">
                    <div v-if="selectedStep.actionNext">
                        <div v-if="selectedStep.textNext">
                            <div class="attentive-buttons">
                                <button class="btn-default" @click="executeAction(selectedStep.actionNext)">{{
                                    selectedStep.textNext
                                    }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <div class="attentive-buttons">
                                <button class="btn-default"
                                    @click="executeAction(selectedStep.actionNext)">PRÓXIMO</button>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="selectedStep.actionCancel != null && selectedStep.actionCancel != undefined && selectedStep.actionCancel != ''">
                        <div class="attentive-buttons" style="margin-top: 20px; margin-bottom: 10px;">
                            <button v-if="selectedStep.id > 0" class="btn-cancel"
                                @click="executeAction(selectedStep.actionCancel)">CANCELAR</button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="attentive-buttons" style="margin-top: 20px; margin-bottom: 10px;">
                            <button v-if="selectedStep.id > 0" class="btn-cancel"
                                @click="redirectStep(16)">CANCELAR</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}
</style>


<script>
import { mapGetters, mapActions } from "vuex";

class Node {
    constructor(data) {
        this.data = data;
        this.previous = null;
        this.next = null;
    }
}

class LinkedList {
    constructor() {
        this.head = null;
        this.tail = null;
    }

    add(data) {
        const newNode = new Node(data);
        if (!this.head) {
            this.head = newNode;
            this.tail = newNode;
        } else {
            this.tail.next = newNode;
            newNode.previous = this.tail;
            this.tail = newNode;
        }
    }

    removeLast() {
        if (!this.tail) {
            return;
        }
        if (this.head === this.tail) {
            this.head = null;
            this.tail = null;
            return;
        }
        this.tail = this.tail.previous;
        this.tail.next = null;
    }

    toArrayWithNeighbors() {
        let current = this.head;
        const array = [];
        while (current) {
            const item = {
                value: current.data,
                previous: current.previous ? current.previous.data : null,
                next: current.next ? current.next.data : null
            };
            array.push(item);
            current = current.next;
        }
        return array;
    }

    hasPrevious() {
        return this.tail && this.tail.previous ? true : false;
    }
}

export default {
    props: {
        steps: {
            type: Array,
            default: () => []
        },
        model: Object
    },
    data() {
        return {
            linkedList: new LinkedList(),
            currentItem: 0,
            currentObject: null,
            currentValue: 0,
            propertys: null,
            infoFiles: [],
            responsibleInfo: null,
            isLoading: false,
            termsFormAssistence: false,
        };
    },
    mounted() {
        for (const key in this.propertys) {
            if (key === 'states' || key === 'resumePatient') {
                continue;
            }
            if (Array.isArray(this.propertys[key])) {
                this.propertys[key] = [];
            } else if (typeof this.propertys[key] === 'boolean') {
                this.propertys[key] = false;
            } else {
                this.propertys[key] = null;
            }
        }
    },

    created() {
        if (!this.isAuthenticated() || this.User == null) {
            this.$router.push("/login");
        }
        else if (this.PatientInfo == null) {
            this.backDaily();
        }
        else {
            this.propertys = { ...this.model };
            if (this.User.professional_type_id == 2) {
                this.linkedList.add(0)
                this.currentValue = 0;
            }
            else {
                this.linkedList.add(2);
                this.currentValue = 2;
            }
        }
        for (const key in this.propertys) {
            if (key === 'states' || key === 'resumePatient') {
                continue;
            }
            if (Array.isArray(this.propertys[key])) {
                this.propertys[key] = [];
            } else if (typeof this.propertys[key] === 'boolean') {
                this.propertys[key] = false;
            } else {
                this.propertys[key] = null;
            }
        }
    },
    computed: {
        ...mapGetters({ PatientInfo: "StatePatient", User: "StateUser", Specialty: "StateSpecialty", Responsible: "StateResponsible", Protocol: "StateProtocol" }),
        selectedStep() {
            return this.steps.find(step => step.id === this.currentValue) || null;
        }
    },
    methods: {
        ...mapGetters(["isAuthenticated"]),
        ...mapActions(["GetResponsibleCFM"]),
        ...mapActions(["RegisterResponsible"]),
        ...mapActions(["CreateProtocol"]),
        ...mapActions(["UploadFile"]),
        previousStep() {
            const object = this.steps.find(step => step.id === this.currentValue) || null;
            if (object) {
                const content = object.content || [];
                content.forEach(item => {
                    const model = item.model;
                    if (model) {
                        this.propertys[model] = Array.isArray(this.propertys[model]) ? [] : null;
                    }
                });
            }
            if (this.currentItem > 0) {
                this.currentItem--;
                this.currentObject = this.linkedList.toArrayWithNeighbors()[this.currentItem];
                this.currentValue = this.currentObject.value
                this.linkedList.removeLast();
            }
            else {
                this.backDaily();
            }
        },
        nextStep() {
            const linkedListLength = this.linkedList.toArrayWithNeighbors().length;
            if (this.currentItem < linkedListLength - 1) {
                this.currentItem++;
                this.currentObject = this.linkedList.toArrayWithNeighbors()[this.currentItem];
                this.currentValue = this.currentObject.value
            }
        },
        redirectStep(step) {
            this.linkedList.add(step);
            this.nextStep();
        },
        executeAction(actionName, value, model, step) {
            if (typeof this[actionName] === 'function') {
                this[actionName](value, model, step);
            } else {
                console.error(`Método ${actionName} não existe`);
            }
        },
        handleClick(value, model, step) {
            this.propertys[model] = value;
            this.redirectStep(step)
        },
        replaceVariables(text) {
            if (text != '' && text != undefined) {
                const userName = this.User && this.User.name ? this.User.name : 'N/D';
                text = text.replace('{{ User.name }}', userName);

                const patientName = this.PatientInfo && this.PatientInfo.name && this.PatientInfo.name.trim() !== ''
                    ? this.PatientInfo.name
                    : 'N/D';
                text = text.replace('{{ PatientInfo.name }}', patientName);

                const patientAge = this.PatientInfo && this.PatientInfo.birthdate && this.PatientInfo.birthdate.trim() !== ''
                    ? this.calculateAge(this.PatientInfo.birthdate)
                    : 0;
                text = text.replace('{{ PatientInfo.birthdate }}', patientAge);
            }
            return text
        },
        removeFile(index) {
            this.propertys['files'].splice(index, 1);
            this.$refs.fileInput.value = '';
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                var newFile = {
                    file: file,
                    name: file.name
                }
                this.propertys['files'].push(newFile)
                event.target.value = '';
            }
        },
        verifyOther(item) {
            if (this.propertys[item.model].includes(item.property)) {
                this.propertys[item.modelAction] = true;
            }
            else {
                this.propertys[item.modelAction] = false;
            }
        },
        calculateAge(dataNascimento) {
            if (dataNascimento !== null) {
                var partes = dataNascimento.split(' ');
                var dataPartes = partes[0].split('/');
                var horaPartes = partes.length > 1 ? partes[1].split(':') : [0, 0];
                var dataNasc = new Date(dataPartes[2], dataPartes[1] - 1, dataPartes[0], horaPartes[0], horaPartes[1]);
                var hoje = new Date();
                var idade = hoje.getFullYear() - dataNasc.getFullYear();
                var mes = hoje.getMonth() - dataNasc.getMonth();
                var dia = hoje.getDate() - dataNasc.getDate();
                if (mes < 0 || (mes === 0 && dia < 0)) {
                    idade--;
                }
                partes = dataNascimento.split(' ');
                var dataFormatada = partes[0]
                if (idade < 1) {
                    var meses = (hoje.getMonth() - dataNasc.getMonth()) + (12 * (hoje.getFullYear() - dataNasc.getFullYear()));
                    return dataFormatada + " (" + meses + " Meses)";
                } else {
                    return dataFormatada + " (" + idade + " Anos)";
                }
            }
        },
        redirectStepInTerm() {
            if (this.propertys["termsFormAssistence"] == false || this.propertys["termsFormAssistence"] == null) {
                this.$toast.warning('É preciso aceitar os Termos de responsabilidade para continuar.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.redirectStep(1);
            }
        },
        async validateCFM() {
            if (this.propertys['crm'] == null || this.propertys['crm'] == '') {
                this.$toast.warning('É preciso preencher dados do CRM.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (this.propertys['selectedState'] == null) {
                    this.$toast.warning('É preciso preencher o Estado.', {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
                else {
                    let responsibleData = {
                        "registration_code": this.propertys['crm'],
                        "state_code": this.propertys['selectedState']['code']
                    }
                    try {
                        this.responsibleInfo = await this.GetResponsibleCFM(responsibleData)
                        if (this.responsibleInfo != null) {
                            let responsibleRegister = {
                                "professional_code": this.responsibleInfo.registration_code.toString(),
                                "state_code": this.responsibleInfo.state_code,
                                "health_professional_type": "CRM",
                                "name": this.responsibleInfo.name,
                                "phone": this.phone
                            }
                            await this.RegisterResponsible(responsibleRegister)
                            this.redirectStep(2);
                        }
                        else {
                            this.$toast.warning('Informações do médico responsavel incorretas.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                    }
                    catch (erro) {
                        this.$toast.error('Erro ao registrar o responsável.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                }
            }
        },
        backDaily() {
            if (this.Specialty.type.name == "Teleinterconsulta") {
                this.$router.push("/");
            }
            else {
                this.$router.push("/daily");
            }
        },
        validateInputAlphanumeric(event, model) {
            let value = event.target.value;
            value = value.replace(/[^a-zA-Z0-9\s\-~^´"?!:;_+/`']/g, '');
            this.propertys[model] = value;
            event.target.value = value;
        },
        validateInputNumber(event, model) {
            let value = event.target.value;
            value = value.replace(/\D/g, '');
            this.propertys[model] = value;
            event.target.value = value;
        },
        redirectSuport02() {
            if (this.propertys['supportTypeO2'] == null || this.propertys['supportTypeO2'] == '') {
                this.$toast.warning('É preciso preencher dados de suporte O2.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.linkedList.add(10);
                this.nextStep();
            }
        },
        renderTrueFalseText(value) {
            if (value) {
                return "Sim"
            }
            else {
                return "Não"
            }
        },
        redirectTypeDrugs() {
            const isSelectedCheckboxesEmpty = this.propertys['selectedCheckboxes'].length === 0;
            const isOtherValueDrugsEmpty = !this.propertys['otherValueDrugs'];
            if (isSelectedCheckboxesEmpty && isOtherValueDrugsEmpty) {
                this.$toast.warning('É preciso preencher dados do tipo de droga vasoativa.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                if (this.propertys['otherValueDrugs']) {
                    this.propertys['selectedCheckboxes'].push(this.propertys['otherValueDrugs'])
                }
                this.linkedList.add(12);
                this.nextStep();
            }
        },
        redirectUpload() {
            if (this.propertys['files'].length == 0) {
                this.$toast.warning('É preciso realizar upload dos arquivos.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.propertys['filesNames'] = this.propertys['files'].map(file => file.name);
                this.linkedList.add(15);
                this.nextStep();
            }
        },
        redirectResume() {
            if (this.propertys['resume'] == null || this.propertys['resume'] == '') {
                this.$toast.warning('É preciso preencher dados de quadro clínico.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.linkedList.add(13);
                this.nextStep();
            }
        },
        redirectFreqbpm() {
            if (this.propertys['freqbpm'] == null || this.propertys['freqbpm'] == '') {
                this.$toast.warning('É preciso preencher dados de Frequência cardíaca.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.linkedList.add(5);
                this.nextStep();
            }
        },
        redirectBalance() {
            if (this.propertys['hydrologicalBalance'] == null || this.propertys['hydrologicalBalance'] == '') {
                this.$toast.warning('É preciso preencher dados de Balanço Hidrico.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            else {
                this.linkedList.add(6);
                this.nextStep();
            }
        },
        async uploadFiles() {
            for (const file of this.propertys['files']) {
                var result = await this.UploadFile(file.file)
                if (result != null) {
                    this.infoFiles.push(result.id)
                }
                else {
                    this.$toast.error(`Ocorreu um erro ao realizar o upload ${file.file.name}`, {
                        timeout: 3000,
                        closeOnClick: true
                    });
                }
            }
        },
        async finishSolicitation() {
            this.isLoading = true
            await this.uploadFiles();
            if (this.infoFiles.length == this.propertys['files'].length) {
                const now = new Date();
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0');
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                if (this.Protocol == null) {
                    let protocolInfo = {
                        'first_event': formattedDateTime,
                        'patient_id': this.PatientInfo.id,
                        'company_id': this.User.companies_info[0].id,
                        'case_type_id': 1,
                        'case_priority_id': 2,
                        'case_location_id': 3,
                        'specialty_id': this.Specialty.id,
                        'outcome_type_id': 4,
                        'responsible_id': this.Responsible ? this.Responsible.id : this.User.professional_type_id == 1 ? this.User.id : null,
                        'nurse_id': this.User.professional_type_id == 2 ? this.User.id : null,
                        'overwrite': false
                    }
                    this.propertys['resumePatient'].forEach(info => {
                        protocolInfo[info.object] = this.propertys[info.property];
                    });
                    if (this.infoFiles && this.infoFiles.length > 0) {
                        protocolInfo['files'] = this.infoFiles
                    }
                    if (this.propertys['selectedCheckboxes'] && this.propertys['selectedCheckboxes'].length > 0) {
                        protocolInfo['patient_drugs'] = this.propertys['selectedCheckboxes'].join(',');
                    }
                    else {
                        protocolInfo['patient_drugs'] = ''
                    }
                    try {
                        let result = await this.CreateProtocol(protocolInfo);
                        if (result == 200) {
                            this.$toast.success('Protocolo de admissão registrado com sucesso.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                            this.backDaily();
                            this.$store.commit('setProtocol', null);
                            this.$store.commit('setPatient', null);
                        }
                        else if (result == 400) {
                            this.$toast.warning('Protocolo já criado nesse caso.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                        else {
                            this.$toast.error('Erro ao criar o protocolo.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                    }
                    catch (erro) {
                        this.$toast.error('Erro ao criar o protocolo.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                }
                else {
                    let protocolInfo = {
                        'first_event': formattedDateTime,
                        'patient_id': this.PatientInfo.id,
                        'company_id': this.User.companies_info[0].id,
                        'protocol_id': this.Protocol.id,
                        'case_type_id': 2,
                        'case_priority_id': 2,
                        'case_location_id': 3,
                        'specialty_id': this.Specialty.id,
                        'responsible_id': this.Responsible ? this.Responsible.id : this.User.professional_type_id == 1 ? this.User.id : null,
                        'nurse_id': this.User.professional_type_id == 2 ? this.User.id : null,
                        'overwrite': false
                    }
                    this.propertys['resumePatient'].forEach(info => {
                        protocolInfo[info.object] = this.propertys[info.property];
                    });
                    if (this.infoFiles && this.infoFiles.length > 0) {
                        protocolInfo['files'] = this.infoFiles
                    }
                    if (this.propertys['selectedCheckboxes'] && this.propertys['selectedCheckboxes'].length > 0) {
                        protocolInfo['patient_drugs'] = this.propertys['selectedCheckboxes'].join(',');
                    }
                    else {
                        protocolInfo['patient_drugs'] = ''
                    }
                    try {
                        let result = await this.CreateProtocol(protocolInfo);
                        if (result == 200) {
                            this.$toast.success('Protocolo de evolução registrado com sucesso.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                            this.backDaily();
                            this.$store.commit('setProtocol', null);
                            this.$store.commit('setPatient', null);
                        }
                        else if (result == 400) {
                            this.$toast.warning('Protocolo já criado nesse caso.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                        else {
                            this.$toast.error('Erro ao criar o protocolo.', {
                                timeout: 3000,
                                closeOnClick: true
                            });
                        }
                    }
                    catch (erro) {
                        this.$toast.error('Erro ao criar o protocolo.', {
                            timeout: 3000,
                            closeOnClick: true
                        });
                    }
                }
            }
            else {
                this.$toast.error('Erro ao realizar upload de arquivos.', {
                    timeout: 3000,
                    closeOnClick: true
                });
            }
            this.isLoading = false
        },
    },
};
</script>

<style scoped>
@import "vue-select/dist/vue-select.css";

:deep(.v-select) {
    --vs-dropdown-max-height: 200px;
    --vs-border-width: 2px;
    --vs-border-style: solid;
    --vs-border-radius: 25px;
    --vs-border-color: #CCCCCC;
    --vs-dropdown-color: #777777;
    --vs-line-height: 2.0;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.file-details {
    display: flex;
    flex-direction: column;
}

.loading {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}

.input-container {
    position: relative;
    margin-bottom: 20px;
}

.input-field {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline-width: 0;
}

.border-in {
    border-bottom: 2px solid #E4E4E4;
    padding-bottom: 10px;
}


.style-font-bold {
    color: #777777;
    font-weight: bold;
}

.style-font {
    color: #777777;
}

.button-back {
    background-color: #ffff;
    border: none;
}

.justified-text {
    color: #777777;
    text-align: justify;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: normal;
    word-spacing: 0.01em;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.flex-container {
    align-items: center;
}

@media screen and (max-width: 368px) {
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 80vh;
        max-width: 100%;
        padding: 0 10px;
    }

    .flex-container {
        align-items: center;
    }

    .content-container-up {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 120vh;
    }

    .flow-down {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px;
    }

    .justified-text {
        color: #777777;
        text-align: justify;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: normal;
        word-spacing: 0.01em;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    .form-check-input {
        width: 30px;
    }
}

.attentive-buttons {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.btn-default {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6200EE;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-cancel {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #6200ee;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-noborder {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #6200ee;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    margin: 0 auto;
}

.custom-select {
    border: 2px solid #CCCCCC;
    border-radius: 25px;
    padding: 5px;
    font-size: 16px;
    color: #777777;
    background-color: white;
}

.custom-select option {
    text-align: center;
}

.custom-select:focus {
    outline: none;
    border-color: #777777;
}
</style>
