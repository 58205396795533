<template>
  <div class="container">
    <div class="conteiner mt-5 mb-5">
      <div class="row">
        <div class="col-1"></div>
        <div class="col-10 ">
          <div class="d-flex align-items-center">
            <button class="button-back" @click="backReport()">
                <img src="../assets/back-button.png" alt="Button Image">
            </button>
            <div class="ms-2 mt-2">
                <h4 class="text-font">Meu histórico</h4>
            </div>
          </div>
          <div class="search-bar mt-4">
            <input v-model="inputValueSearch" type="search" class="form-control text-font-regular background-search"
              placeholder="Buscar paciente" aria-label="Search" aria-describedby="search-addon"
              @keydown.enter.prevent="handleChangeSearch" v-on:search="clearSearch" style="border-radius: 20px 0 0 20px;"/>
            <div class="clear-button"></div>
            <button class="search-button" @click="handleChangeSearch" style="border-radius:0 20px 20px 0;">
              <img src="../assets/search-icon-white.svg" alt="Ícone de pesquisa">
            </button>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
    </div>
    <div class="conteiner mt-5 mb-5">
      <div class="row mt-5">
        <div class="col-1"></div>
        <div class="col-10">
        </div>
        <div class="col-1"></div>
      </div>
      <div v-if="isLoading === true">
        <div class="conteiner mt-5 mb-5">
          <div class="row mt-5">
            <div class="row mt-4">
              <div class="col-2"></div>
              <div class="col-8">
                <div class="centered-container">
                  <div class="loading"></div>
                </div>
                <p class="text-font-regular" style="margin-top: 10px; text-align: center">
                  Carregando...
                </p>
              </div>
              <div class="col-2"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="verifyHistorics()">
          <ul style="list-style-type: none">
            <li v-for="historic in HistoricosResult.reports_info" v-bind:key="historic.id">
              <div class="row mt-4">
                <div class="col-1"></div>
                <div class="col-10">
                  <div class="card">
                    <div class="card-body">
                      <p class="card-text setup-text-card fw-bold">
                        Paciente:
                        {{ formatName(historic.protocol_update_info.protocol_info.patient_info.name) }}
                      </p>
                      <p class="card-text setup-text-card fw-bold">
                          Especialidade:
                          <span style="color: #3498db;">{{ historic.protocol_update_info.protocol_info.specialty }}</span>
                      </p>
                      <p class="card-text setup-text-card fw-bold">
                          Número de protocolo:
                          {{ historic.protocol_update_info.protocol_info.number }}
                      </p>
                      <p class="card-text setup-text-card fw-bold">
                        Data de emissão: {{ historic.protocol_update_info.created_at }}
                      </p>
                      <div class="mt-4"></div>
                      <div class="d-flex justify-content-between">
                        <a></a>
                        <button class="btn btn-secondary_pep fw-bold" style="text-align: right" @click="openReview(historic)">
                          VER CASO
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-1"></div>
              </div>
            </li>
          </ul>
          <div class="row mt-4">
            <div class="col-12 d-flex justify-content-center">
              <div class="overflow-auto">
                <b-pagination v-model="page" :total-rows="count" :per-page="pageSize" prev-text="Anterior"
                  next-text="Próximo"></b-pagination>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row mt-4">
            <div class="col-2"></div>
            <div class="col-8">
              <p class="text-font-regular" style="text-align: center">
                Não há casos em histórico.
              </p>
            </div>
            <div class="col-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.clear-button {
  margin-left: 44px;
}

.backgound-imagem-search {
  background-color: #6200EE;
  max-width: 200px;
  max-height: 150px;
  width: auto;
  height: auto;
}

.setup-text-card-title {
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.setup-text-card {
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin: 0;
  padding: 0;
}

.btn-secondary_pep {
  background-color: #ffffff;
  color: #6200ee;
  padding: 0;
  border: none;
  background: none;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.card-border-unavailable {
  border-left: 8px solid #bebebe;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  position: relative;
}

.search-button {
  height: 36px;
  width: 40px;
  background-color: #6200EE;
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.search-button img {
  width: 20px;
  height: 20px;
  margin-bottom: 1px;
  object-fit: cover;
}

.search-bar i {
  margin-right: 10px;
}

.search-bar input[type="search"] {
  flex: 1;
  border: none;
  outline: none;
}

.background-search {
  background-color: #f0f0f0;
}

.text-font {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #777777;
}


.text-font-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #777777;
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: `Historical`,
  computed: {
    ...mapGetters({ User: "StateUser" }),
  },
  async created() {
    if (!this.isAuthenticated()) {
      this.$router.push("/login");
    } else {
      try {
        let paramsFilters = {
          filters: {
            title: '',
            finished: 1,
          },
          page: this.page,
          limit: this.pageSize
        };
        this.HistoricosResult = await this.GetHistorics(paramsFilters);
        this.count = this.HistoricosResult.total;
        this.isLoading = false;
      } catch (error) {
        console.error('Erro ao obter históricos:', error);
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      }
    }
  },
  watch: {
    async page(newPage, oldPage) {
      if (newPage !== oldPage) {
        await this.retrieveHistorics();
      }
    },
  },
  data() {
    return {
      HistoricosResult: null,
      isLoading: true,
      inputValueSearch: null,
      page: 1,
      count: 1,
      pageSize: 10
    };
  },
  async mounted() {

  },
  methods: {
    ...mapActions(["GetReportById"]),
    ...mapActions(["GetHistorics"]),
    ...mapGetters(["isAuthenticated"]),
    backReport() {
        this.$router.push('/historymenu');
    },
    async clearSearch() {
      this.page = 1;
      this.inputValueSearch = ""
      await this.retrieveHistorics();
    },
    verifyOwner() {
      return true;
    },
    verifyHistorics() {
      if (this.HistoricosResult != null) {
        if (this.HistoricosResult.reports_info.length > 0) {
          return true;
        }
      }
      return false;
    },
    formatPhone(phone) {
      if (phone != null) {
        const ddd = phone.substring(2, 4);
        const first = phone.substring(4, 9);
        const second = phone.substring(9);
        return `${ddd} ${first}-${second}`;
      }
    },
    formatName(name) {
      if (name != null) {
        let words = name.toLowerCase().split(" ");
        for (let i = 0; i < words.length; i++) {
          let word = words[i];
          words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
        return words.join(" ");
      }
    },
    async openReview(report) {
      await this.GetReportById(report.id);
      this.$router.push({ name: 'HistoryMe' });
    },
    getRequestParams(searchTitle, page, pageSize) {
      let params = {};
      params = {
        filters: {
          title: searchTitle,
          finished: 1,
        },
        page: page,
        limit: pageSize
      }
      return params;
    },
    async retrieveHistorics() {
      const params = this.getRequestParams(
        this.inputValueSearch,
        this.page,
        this.pageSize
      );

      this.isLoading = true;

      try {
        const historicosResult = await this.GetHistorics(params);
        this.HistoricosResult = historicosResult;
        this.count = historicosResult.total;
      } catch (error) {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      } finally {
        this.isLoading = false;
      }
    },
    async handleChangeSearch() {
      this.page = 1;
      if (this.inputValueSearch != null) {
        if (this.inputValueSearch.length >= 2) {
          await this.retrieveHistorics();
        }
      }
    },
    findUpdatedAtForStep4(statuses) {
      const status = statuses.find((status) => status.step === 4);
      if (status) {
        if (status.status == "success") {
          return this.formatUpdatedAt(status.updated_at);
        }
        return null;
      } else {
        return null;
      }
    },
    formatUpdatedAt(updated_at) {
      const date = new Date(updated_at);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  },
};
</script>
