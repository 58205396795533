<template>
  <div class="container">
    <div class="conteiner mt-5 mb-5">
      <div class="row">
        <div class="col-10 d-flex align-items-center">
          <div>
              <button class="button-back mx-3" @click="backReport">
                  <img src="../assets/back-button.png" alt="Button Image">
              </button>
          </div>
          <div class="mt-2">
              <h3 class="text-font">Histórico</h3>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
    </div>
    <div class="conteiner mt-5 mb-5">
      <div class="col-10 mt-5">
        <div class="col-12">
            <h4>Selecione uma opção abaixo:</h4>
        </div>
        <div class="col-10">
            <div class="row mt-3" style="cursor: pointer">
                <div class="border-in" @click="historic" style="display: flex; align-items: center;">
                    <img src="../assets/myHistoric.png" style="margin-right: 10px;">
                    <div style="display: flex; flex-direction: column;">
                        <h4 class="text-font-bold" style="margin: 0;">Meu histórico</h4>
                        <p class="text-font-bold" style="margin: 0;">Histórico de minhas solicitaçoes</p>
                    </div>
                    <div style="display: flex; align-items: center; margin-left: auto;">
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
            </div>
            <div class="row mt-3" style="cursor: pointer">
                <div class="border-in" @click="historicalhospital" style="display: flex; align-items: center;">
                    <img src="../assets/hospitalHistoric.png" style="margin-right: 10px;">
                    <div style="display: flex; flex-direction: column;">
                        <h4 class="text-font-bold" style="margin: 0;">Histórico do hospital</h4>
                        <p class="text-font-bold" style="margin: 0;">Histórico das solicitações do hospital</p>
                    </div>
                    <div style="display: flex; align-items: center; margin-left: auto;">
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-1"></div>
      </div>

    </div>
  </div>
</template>
<style scoped>
.clear-button {
  margin-left: 44px;
}

.backgound-imagem-search {
  background-color: #6200EE;
  max-width: 200px;
  max-height: 150px;
  width: auto;
  height: auto;
}

.setup-text-card-title {
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.setup-text-card {
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin: 0;
  padding: 0;
}

.btn-secondary_pep {
  background-color: #ffffff;
  color: #6200ee;
  padding: 0;
  border: none;
  background: none;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.card-border-unavailable {
  border-left: 8px solid #bebebe;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  position: relative;
}

.search-button {
  height: 36px;
  width: 40px;
  background-color: #6200EE;
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.search-button img {
  width: 20px;
  height: 20px;
  margin-bottom: 1px;
  object-fit: cover;
}

.search-bar i {
  margin-right: 10px;
}

.search-bar input[type="search"] {
  flex: 1;
  border: none;
  outline: none;
}

.background-search {
  background-color: #f0f0f0;
}

.text-font {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #777777;
}


.text-font-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #777777;
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: `Historical`,
  computed: {
    ...mapGetters({ User: "StateUser" }),
  },
  methods: {
    ...mapActions(["GetHistorics"]),
    ...mapGetters(["isAuthenticated"]),
    async historic() {
        this.$router.push("/history");
    },
    async historicalhospital() {
        this.$router.push("/historycompany");
    },
    backReport() {
        this.$router.push('/');
    },
    async clearSearch() {
      this.page = 1;
      this.inputValueSearch = ""
      await this.retrieveHistorics();
    },
    verifyOwner() {
      return true;
    },
    verifyHistorics() {
      if (this.HistoricosResult != null) {
        if (this.HistoricosResult.reports_info.length > 0) {
          return true;
        }
      }
      return false;
    },
    formatPhone(phone) {
      if (phone != null) {
        const ddd = phone.substring(2, 4);
        const first = phone.substring(4, 9);
        const second = phone.substring(9);
        return `${ddd} ${first}-${second}`;
      }
    },
    formatName(name) {
      if (name != null) {
        let words = name.toLowerCase().split(" ");
        for (let i = 0; i < words.length; i++) {
          let word = words[i];
          words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
        return words.join(" ");
      }
    },
    async openReview(report) {
      await this.GetReportById(report.id);
      this.$router.push({ name: 'HistoricalMe' });
    },
    getRequestParams(searchTitle, page, pageSize) {
      let params = {};
      params = {
        filters: {
          title: searchTitle,
          finished: 1,
          company_id: this.user.company_id
        },
        page: page,
        limit: pageSize
      }
      return params;
    },
    async retrieveHistorics() {
      const params = this.getRequestParams(
        this.inputValueSearch,
        this.page,
        this.pageSize
      );

      this.isLoading = true;

      try {
        const historicosResult = await this.GetHistorics(params);
        this.HistoricosResult = historicosResult;
        this.count = historicosResult.total;
      } catch (error) {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      } finally {
        this.isLoading = false;
      }
    },
    async handleChangeSearch() {
      this.page = 1;
      if (this.inputValueSearch != null) {
        if (this.inputValueSearch.length >= 2) {
          await this.retrieveHistorics();
        }
      }
    },
    findUpdatedAtForStep4(statuses) {
      const status = statuses.find((status) => status.step === 4);
      if (status) {
        if (status.status == "success") {
          return this.formatUpdatedAt(status.updated_at);
        }
        return null;
      } else {
        return null;
      }
    },
    formatUpdatedAt(updated_at) {
      const date = new Date(updated_at);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  },
};
</script>
